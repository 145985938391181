import getFormatMessage from "../../locales/get-format-message";

export const openURL = (url: string, name?: string, blank?: boolean) => {
  window.open(url, name, blank ? '_blank' : undefined);
};

export const getURLPathname = () => {
  return `${window.location.origin}${window.location.pathname}`;
};

export const reload = () => {
  window.location.reload();
};

export const back = () => {
  window.history.go(-1);
};

export const getLocationOrigin = () => {
  return window.location.origin;
};

export const replaceURL = (url: string) => {
  window.location.href = url;
};

export const setTitle = (title: string) => {
  document.title = title || getFormatMessage('my_studios_portal');
};
