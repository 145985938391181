import styles from './style.module.scss';
// import { RightOutlined } from '@ant-design/icons';
import { IconDetailItem } from '../../common/icon-detail-item';
// import { Button } from 'antd';
import { BoxFrame } from '../../common/box-frame';
import getFormatMessage from '../../../locales/get-format-message';
import { ReactComponent as Icon1 } from '../../../assets/svg/c-icon-detail-item-icon-01.svg';
import { ReactComponent as Icon2 } from '../../../assets/svg/c-icon-detail-item-icon-02.svg';
import { ReactComponent as Icon3 } from '../../../assets/svg/c-icon-detail-item-icon-03.svg';

export const DevelopersBox = () => {
  return (
    <BoxFrame
      className={styles.developersBox}
      innerClassName={styles.developersInnerBox}
    >
      <span className={styles.sideImgBox}>
        <video src="/video/ufo-loop.mp4" muted autoPlay loop />
      </span>
      <div className={styles.developerInfoBox}>
        <h3>
          {getFormatMessage('let_developers_focus_core')}
          <span className="color-import">
            {getFormatMessage('gameplay_experience')}
          </span>
        </h3>
        <div>
          <p className="developer-info">
            {getFormatMessage('developers_want_to_focus_desc')}
          </p>
          <p className="developer-info">
            {getFormatMessage('now_developers_can_stay_desc')}
          </p>
          <p className="developer-info is-color-import">
            {getFormatMessage('so_what_you_waiting_desc')}
          </p>
        </div>
        {/* <span>
          <Button type="primary" className="c-btn-size-md">
            {getFormatMessage('explore_open_devportal')}
            <RightOutlined />
          </Button>
        </span> */}
      </div>
      <div className={styles.developerDetailListBox}>
        <h4 className={styles.developerDetailTitle}>
          {getFormatMessage('how_developers_benefit')}
        </h4>
        <ul className={styles.detailList}>
          <li className="list-item">
            <IconDetailItem
              icon={<Icon1 />}
              title={getFormatMessage('cross_cloud_communication')}
              detail={getFormatMessage(
                'suppots_inter_services_communication_across'
              )}
            />
          </li>
          <li className="list-item">
            <IconDetailItem
              icon={<Icon2 />}
              title={getFormatMessage('highly_scalable_short')}
              detail={getFormatMessage('supports_non_destructive')}
            />
          </li>
          <li className="list-item">
            <IconDetailItem
              icon={<Icon3 />}
              title={getFormatMessage('fully_hosted')}
              detail={getFormatMessage('supports_failover_services_governance')}
            />
          </li>
        </ul>
      </div>
    </BoxFrame>
  );
};
