export enum LoadImageStatus {
  fail = 'fail',
  success = 'success',
  overtime = 'overtime',
}

export class LoadImage {
  public image: null | HTMLImageElement = null;

  public url(
    url: string,
    handle: (status: LoadImageStatus, instance: LoadImage) => void
  ) {
    this.image = new Image();
    this.image.onerror = () => {
      handle(LoadImageStatus.fail, this);
    };
    this.image.onload = () => {
      handle(LoadImageStatus.success, this);
    };
    this.image.src = url;
  }

  public destroy() {
    this.image = null;
  }
}
