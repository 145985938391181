import React, { useState } from 'react';
import { Modal } from 'antd';
import './style.scss';
import classNames from 'classnames';
import getFormatMessage from '../../locales/get-format-message';
interface IProps {
  readonly onClose?: () => void;
  readonly width?: string;
  readonly onOk?: () => Promise<boolean>;
  readonly oKText?: string;
  readonly cancelText?: string;
  readonly title?: React.ReactNode | string;
  readonly children: React.ReactNode;
  readonly className?: string;
  readonly footer?: React.ReactNode;
  readonly loading?: boolean;
  readonly okButtonProps?: { [key: string]: any };
  readonly maskClosable?: boolean;
}
export const ModalWrap = (props: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const onOk = async () => {
    if (props.onOk) {
      if (loading) {
        return;
      }
      setLoading(true);
      await props.onOk();
      setLoading(false);
    }
  };
  const renderFooter = () => {
    const classnames = classNames('gc-btn gc-btn-primary', {
      disabled: loading,
    });
    return (
      <div className="gc-modal-footer">
        <div className="col-right">
          {props.cancelText ? (
            <button
              className="gc-btn gc-btn-default"
              type="button"
              onClick={props.onClose}
            >
              <span className="btn-text">{props.cancelText || getFormatMessage('cancel')}</span>
            </button>
          ) : null}
          {props.oKText ? (
            <button className={classnames} type="button" onClick={onOk}>
              <span className="btn-text">{props.oKText || getFormatMessage('save')}</span>
            </button>
          ) : null}
        </div>
      </div>
    );
  };
  const classnames = classNames('modal-wrap', props.className);
  return (
    <Modal
      className={classnames}
      title={props.title}
      width={props.width || 600}
      open={true}
      onCancel={props.onClose}
      maskClosable={props.maskClosable || false}
      getContainer={false}
      centered={true}
      footer={props.footer || renderFooter()}
    >
      {props.children}
    </Modal>
  );
};
