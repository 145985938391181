import { Redirect } from 'react-router-dom';
import { RouteConfig } from 'react-router-config';
import { NotFound } from '../pages/not-found';
import { HomeRoute } from '../pages/home';
import { GetStarted } from '../pages/get-started';
import { TemplateEmpty } from './templates/empty';

const NOT_FOUND_ROUTE: RouteConfig[] = [
  {
    path: '/404',
    exact: true,
    component: NotFound,
  },
  {
    path: '*',
    render: () => <Redirect to={'/404'} />,
  },
];

const childRoutes: RouteConfig[] = [
  {
    path: '/',
    exact: true,
    component: HomeRoute,
  },
  {
    path: '/get-started',
    exact: true,
    component: GetStarted,
  },
  ...NOT_FOUND_ROUTE,
];

const ROUTES_CONFIG: RouteConfig[] = [
  {
    path: '/',
    component: TemplateEmpty,
    routes: childRoutes,
  },
];

export default ROUTES_CONFIG;
