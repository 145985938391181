import { Button, Space } from 'antd';
import { BoxFrame } from '../../common/box-frame';
import { LeadStudios } from '../lead-studios';
import styles from './style.module.scss';
import { Fragment } from 'react';
import classNames from 'classnames';
interface IBUttonList {
  readonly buttonText?: string;
  readonly buttonHref?: string;
  readonly buttonType?: 'primary';
  readonly buttonSize?: 'small' | 'large';
}
interface ITItleItemsProps {
  readonly text?: string;
  readonly textColor?: 'primary';
  readonly textSize?: 'small';
}
interface IEXtraTextListProps {
  readonly text?: string;
}
interface IProps {
  readonly titleItems?: ITItleItemsProps[];
  readonly extraTextList?: IEXtraTextListProps[];
  readonly buttonList?: IBUttonList[];
  readonly algn?: 'center';
  readonly hasLeadStudios?: boolean;
}
const TitleItem = (props: IProps) => {
  return (
    <h2>
      {props.titleItems?.map((item, index) => (
        <span
          className={classNames(
            'banner-text',
            item.textColor === 'primary' && 'is-color-primary',
            item.textSize === 'small' && 'is-small'
          )}
          key={index}
        >
          {item.text}
        </span>
      ))}
    </h2>
  );
};
const ExtraTextList = (props: IProps) => {
  return (
    <p className="extra-text">
      {props.extraTextList?.map((item, index) => (
        <Fragment key={index}>
          {item.text}
          {props.extraTextList && props.extraTextList.length > 1 && <br />}
        </Fragment>
      ))}
    </p>
  );
};
const ButtonList = (props: IProps) => {
  return (
    <>
      {props.buttonList && (
        <Space size={24}>
          {props.buttonList?.map((item, index) => (
            <Button
              key={index}
              type={item.buttonType}
              size={item.buttonSize}
              href={item.buttonHref}
            >
              {item.buttonText}
            </Button>
          ))}
        </Space>
      )}
    </>
  );
};

export const BannerStartedBox = (props: IProps) => {
  return (
    <BoxFrame
      className={classNames(
        styles.banner,
        props.algn === 'center' && styles.isCenter
      )}
      innerClassName="banner-inner"
    >
      <Space direction="vertical" size={56} className={styles.bannerInfoBox}>
        <Space direction="vertical" size={8}>
          <TitleItem titleItems={props.titleItems} />
          <ExtraTextList extraTextList={props.extraTextList} />
        </Space>
        <ButtonList buttonList={props.buttonList} />
      </Space>
      {props.hasLeadStudios && <LeadStudios />}
    </BoxFrame>
  );
};
