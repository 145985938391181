export default {
    // 中文配置
    begin_your_development_adventure: '通过下载 SDK、在开发者门户开通服务',
    setting_up_services: '以及深入研究我们的示例和文档，即可轻松开启InGame研发之旅',
    and_diving_into_samples: '',
    embark_on_your_in_game_journey: '开启InGame之旅',
    just_3_steps: '仅需3个简单步骤',
    step_1: 'step 01',
    get_started_with_account: '获取账户并开通感兴趣的服务',
    start_by_leaving_desc: '在“联系我们”页面留下联系信息，InGame团队会在24小时内与您取得联系并提供InGame平台账户。获取账户后，您可以随时开通所需服务并进行服务配置',
    get_your_account: '获取InGame账户',
    step_2: 'step 02',
    download_sdk_link: '下载SDK并关联游戏',
    once_you_logged_in_desc: '登录后可前往下载中心获取最新的 InGame SDK。无论是PC、移动端还是主机平台，我们都提供了对应的支持，在安装了 SDK 后，可以轻松地将其与游戏集成，然后启动我们的服务，开始您的研发之旅',
    log_in_to_desc: '登录开发者门户',
    step_3: 'step 03',
    dive_into_documentation: '探索InGame文档',
    we_have_put_together_comprehensive_desc: '我们为您准备了一套全面的产品文档。其中包括每项服务的产品介绍、API 参考以及 SDK 中包含的示例代码指南。通过探索这些资源，将全面了解我们的产品和服务',
    explore_documentation: '前往文档中心',
    fueling_journey_to_success: '助力开发者游戏梦想起航',
    we_believe_power_connected_desc: '在这里，每个创意都值得被实现，每位开发者都有机会成功',
    has_opportunity_to_shine_desc: 'InGame搭载领先的游戏后端服务，助力开发者游戏梦想扬帆起航',
    we_are_handing_unlock_desc: '',
}