import { BrowserRouter } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import RoutesConfig from '../router';

const Root = () => {
  const renderRouter = () => {
    return renderRoutes(RoutesConfig);
  };

  return <BrowserRouter>{renderRouter()}</BrowserRouter>;
};

export default Root;
