import React from 'react';
import styles from './style.module.scss';
import classNames from 'classnames';

interface IProps {
  readonly children?: React.ReactNode;
  readonly className?: any;
  readonly innerClassName?: any;
  readonly id?: any;
}

export const BoxFrame = (props: IProps) => {
  return (
    <div className={classNames(styles.boxFrame, props.className)} id={props.id}>
      <div className={classNames('frame-content', props.innerClassName)}>
        {props.children}
      </div>
    </div>
  );
};
