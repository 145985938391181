/* eslint-disable camelcase */
// @ts-nocheck
import i18n from 'i18next';
import moment from 'moment';
import { initReactI18next, hashKey } from '@tencent/i18next-mark';
import LanguageDetector from 'i18next-browser-languagedetector';
import en_US from '../languages/en.json';
import zh_CN from '../languages/zh.json';

// const language = localStorage.getItem('language');
// const defaultLocale = language || 'zh_CN';
const defaultLocale = 'en_US';

const resources = {
  en_US: {
    translation: en_US,
  },
  zh_CN: {
    translation: zh_CN,
  },
};

i18n.on('languageChanged', (lng) => {
  localStorage.setItem('language', lng);
});

i18n
  .use(LanguageDetector) // 嗅探当前浏览器语言
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    preload: ['zh_CN', 'en_US'],
    resources,
    lng: defaultLocale,
    fallbackLng: defaultLocale,
    keySeparator: false,
    debug: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
      format: (value, format) => {
        if (format === 'uppercase') return value.toUpperCase();
        if (value instanceof Date) return moment(value).format(format);
        return value;
      },
    },
    detection: {
      order: ['localStorage'],
      lookupLocalStorage: 'i18nextLng',
    },
    react: {
      wait: true,
      useSuspense: false,
      bindI18n: 'languageChanged loaded',
    },
  });

export const t = (sentence: string, options?: any) => {
  const key = hashKey(sentence);
  return i18n.t(key, {
    ...(options || {}),
    defaultValue: sentence,
  });
};

i18n.t.bind(t);
export default i18n;
