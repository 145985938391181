import { getConfig } from '../config';
import { removeCookieToken } from '../pages/lib';
import { getLocaleLanguage, LanguageType } from './language';

const currentLanguage = getLocaleLanguage();

export function jumpConsole(_ticket: any) {
  const { consoleURL } = getConfig();
  const url = new URL(`https://${consoleURL}?show-account-center=1`);
  url.searchParams.append(
    'ingame_language',
    currentLanguage || LanguageType.en_US
  );
  location.href = url.toString();
}

export function jumpLogin(redirectUrl?: string) {
  removeCookieToken();
  const { userURL, consoleURL } = getConfig();
  const backurl = redirectUrl || `${consoleURL}`;
  const ingameLanguage = encodeURIComponent(
    currentLanguage || LanguageType.en_US
  );
  const loginUrl = `${userURL}/email-login?backurl=${encodeURIComponent(
    backurl
  )}&ingame_language=${ingameLanguage}`;
  // const loginUrl = `${userURL}/email-login?backurl=${encodeURIComponent(
  //   backurl
  // )}&show-account-center=1`;
  location.href = loginUrl;
}
export function jumpLoginToAccount() {
  removeCookieToken();
  const { userURL, consoleURL } = getConfig();
  const backurl = `${consoleURL}`;
  const ingameLanguage = encodeURIComponent(
    currentLanguage || LanguageType.en_US
  );
  const loginUrl = `${userURL}/email-login?backurl=${encodeURIComponent(
    backurl
  )}&ingame_language=${ingameLanguage}&show-account-center=1`;
  location.href = loginUrl;
}
