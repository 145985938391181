import LangComponents from './zh-cn/components';
import LangGetStarted from './zh-cn/get-started';
import LangHome from './zh-cn/home';
import LangErrCodeMsg from './zh-cn/err-code-msg';

// 中文配置
const common = {
  get_started:'立即开启',
  get_started_uppercase:'立即开始',
  dev_portal: '开发者门户',
  products_home: '产品首页',
  products: '产品',
  documentation:'文档中心',
  download_center:'下载中心',
  sign_out_label: '退出登录',
  about_us:'关于我们',
  account_label: '账户管理',
  contact_us:'联系我们',
  footer_copyright:'Copyright © 1998 - 2023 Tencent GCloud. All Rights Reserved 腾讯公司 粤B2-20090059',
  privacy_policy:'隐私协议',
  terms_of_service:'服务条款',
  cookies_policy:'Cookies协议',
  oss_information:'OSS信息',
  multiplayer: '多人对战',
  content:'内容更新',
  social:'互动社交',
  economy:'经济系统',
  engage:'提升参与',
  analytics:'数据分析',
  try_for_free:'TRY FOR FREE!',

  create: '创建',
  delete: '删除',
  submit: '提交',
  cancel: '取消',
  close: '关闭',
  save: '保存',
  confirm_to: '前往',
  site_switch_cn: '站点切换',
  site_switch_en: '站点切换',
  site_switch_woa: '站点切换',
  site_switch_cn_desc: '您即将前往InGame中国站，是否继续？',
  site_switch_en_desc: '您即将前往InGame国际站，是否继续？',
  site_switch_woa_desc: '您即将前往InGame内网环境，是否继续？',
  // 中文,英文
  // china:'中文版',
  // english:'English',
  ...LangComponents,
  ...LangGetStarted,
  ...LangHome,
  ...LangErrCodeMsg,
};

// eslint-disable-next-line no-restricted-syntax
export default Object.assign(common);
