import { AppEnvType, IAppConfig } from '../model/config';
// import { getRemoteAppConfig } from '../lib/app';
import { IString } from '../model';
import { getRemoteAppConfig } from '../lib/app';

export const BASE_LOGIN_COOKIE_KEY = 'INTL_GCLOUD_TICKET';
export const ENV_DEV = process.env.REACT_APP_ENV === AppEnvType.Development;
export const MAILTO = 'tatiwang@tencent.com';

// warning
// not prod env, include china or singapore region,
// if this whitelisted game exists in pre-release, it will not be accessible
export const NO_PROD_ENV_SPECIAL_GAME = ['274258010', '47734803'];

const config: any = {
  env: AppEnvType.Development,
  appID: 'intl-gcloud',
  userID: '-',
  areaConfig: null,
};

export const setConfig = (data: IString<any>) => {
  Object.assign(config, data);
};

export const getConfig = (): IAppConfig => config;
Object.assign(window, { getConfig });

export const getLoginCookieKey = () => getConfig().cookieName;
export const isEnvProd = () => getConfig().env === AppEnvType.Production;
export const isEnvDev = () => getConfig().env === AppEnvType.Development;
export const isEnvPre = () => getConfig().env === AppEnvType.PreRelease;
export const isEnvTest = () => getConfig().env === AppEnvType.Test;

/**
 * @description merge 流程，优先级最高 remote config
 * 1. remote config
 * 2. rum time config <env.xxx.ts>
 * 3. local default config.default.ts
 */
export const initAppConfig = async () => {
  const config = await getRemoteAppConfig(
    process.env.REACT_APP_ENV as AppEnvType
  );
  if (config) {
    setConfig(config);
    console.log('App start config', getConfig());
    return getConfig();
  }
  return null;
};
