import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import './styles';
import './index.scss';
import { initTheme } from './lib/theme';
import zhCN from 'antd/es/locale/zh_CN';
import enUS from 'antd/es/locale/en_US';
import { ConfigProvider } from 'antd';
import Root from './pages/root';
import i18n from './config/i18n';
import { I18nextProvider } from '@tencent/i18next-mark';
import { getLocaleLanguage, LanguageType, initAegis } from './lib';
import { ReactComponent as SpriteSvg } from './assets/svg/sprite.svg';
import { initAppConfig } from './config';

const isDev = process.env.REACT_APP_ENV === 'development';

const render = async (Component: () => JSX.Element) => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <ConfigProvider
            locale={getLocaleLanguage() === LanguageType.zh_CN ? zhCN : enUS}
          >
            <Component />
            <SpriteSvg style={{ display: 'none' }} />
          </ConfigProvider>
        </I18nextProvider>
      </Provider>
    </React.StrictMode>,
    document.querySelector('#app')
  );
};

if (isDev) {
  if (module.hot) {
    module.hot.accept();
  }
}

const initialization = async () => {
  const appConfig = await initAppConfig();
  if (!appConfig) {
    return null;
  }

  initAegis();
  initTheme();
  render(Root);
};

initialization();
