import moment from 'moment';

// 明天之后日期不可选
export const getDisabledDateByLater = (current: any) => {
  return current && current > moment().endOf('day');
};

// 今天之前日期不可选
export const getDisabledDateByBefore = (current: any) => {
  return current && current < moment().endOf('day');
};
