import styles from './style.module.scss';
import { Row, Col } from 'antd';
import { BoxFrame } from '../../common/box-frame';
import { ItemTitle } from '../../common/item-title';
import WordLIstIcon1 from '../../../assets/images/c-word-box-list-icon-01.png';
import WordLIstIcon2 from '../../../assets/images/c-word-box-list-icon-02.png';
import WordLIstIcon3 from '../../../assets/images/c-word-box-list-icon-03.png';
import EarthSmallIcon from '../../../assets/images/earth-icon.png';
import getFormatMessage from '../../../locales/get-format-message';
import useExternalScripts from '../../../hooks/use-external-scripts.js';

export const WordBox = () => {
  useExternalScripts('/spline/js/process.js');
  useExternalScripts('/spline/js/spline-viewer.js');

  const isMobile =
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.test(
      navigator.userAgent
    );
  const isiPad =
    navigator.userAgent.match(/(iPad)/) ||
    (navigator.userAgent.match(/(Macintosh)/) && navigator.maxTouchPoints >= 1);

  if (!(isMobile || isiPad)) {
    window.onload = () => {
      // 获取spline-viewer
      const splineViewer = document.querySelector('spline-viewer');
      // 获取shadow dom
      const shadowRoot = splineViewer?.shadowRoot;
      // 获取a标签
      const aTag = shadowRoot?.querySelector('#logo');
      // 移除a标签
      aTag?.remove();
    };

    // 触摸板禁止手指缩放
    document.addEventListener(
      'wheel',
      (event) => {
        event.preventDefault();
      },
      { passive: false }
    );

    // 阻止子元素相同事件冒泡
    document.getElementById('app')?.addEventListener('wheel', (event) => {
      event.stopPropagation();
    });
  }

  return (
    <div className={styles.wordBox}>
      <ItemTitle
        type="inline"
        className={styles.worldItemTitle}
        items={[
          { text: getFormatMessage('global_support'), textColor: 'primary' },
          { text: getFormatMessage('seamless_service') },
        ]}
        itemsExtra={[
          {
            text: getFormatMessage('we_are_committed_desc'),
          },
          {
            text: getFormatMessage('we_have_service_centers_desc'),
          },
          {
            text: getFormatMessage('our_worldwide_battle_server_desc'),
          },
        ]}
      />
      <div className={styles.earthBoxInnerBox}>
        {isMobile || isiPad ? (
          <span className="earth-icon-box">
            <video src="/video/the-earth.mp4" loop autoPlay muted></video>
          </span>
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html:
                '<spline-viewer url="/spline/spline/earth.spline" class="spline-viewer" /> ',
            }}
          />
        )}
      </div>
      <ItemTitle
        items={[{ text: getFormatMessage('deployment_regions') }]}
        size="small"
        innerClassName={styles.deploymentRegionsName}
      />
      <BoxFrame innerClassName={styles.wordDeploymentWrapper}>
        <div className="word-deployment-main-box">
          <ItemTitle
            align="start"
            type="inline"
            items={[
              { text: getFormatMessage('master'), textColor: 'orange' },
              { text: getFormatMessage('deployment_regions_lowercase') },
            ]}
            size="xmini"
          />
          <Row gutter={[16, 4]} wrap>
            <Col span={10}>
              <p className={styles.deploymentText}>
                {getFormatMessage('china_china')}{' '}
                <span className="color-import">
                  {getFormatMessage('shanghai')}
                </span>
              </p>
            </Col>
            <Col span={14}>
              <p className={styles.deploymentText}>
                {getFormatMessage('north_america_us_east')}{' '}
                <span className="color-import">
                  {getFormatMessage('virginia')}
                </span>
              </p>
            </Col>
            <Col span={10}>
              <p className={styles.deploymentText}>
                {getFormatMessage('europe_europe')}
                <span className="color-import">
                  {getFormatMessage('frankfurt')}
                </span>
              </p>
            </Col>
            <Col span={14}>
              <p className={styles.deploymentText}>
                {getFormatMessage('asia_pacific_southeast')}
                <span className="color-import">
                  {getFormatMessage('singapore')}
                </span>
              </p>
            </Col>
          </Row>
        </div>
        <div className="word-deployment-side-box">
          <ItemTitle
            align="start"
            type="inline"
            items={[
              { text: getFormatMessage('dedicated_server'), textColor: 'blue' },
              { text: getFormatMessage('deployment_regions_lowercase') },
            ]}
            size="xmini"
          />
          <Row gutter={[16, 4]} wrap>
            <Col span={24}>
              <p className={styles.deploymentText}>
                {/* all_over_world */}
                <img
                  src={EarthSmallIcon}
                  width={16}
                  height={16}
                  style={{
                    position: 'relative',
                    top: '-2px',
                    marginRight: '5px',
                  }}
                />
                <span className="color-import">
                  {' '}
                  {getFormatMessage('all_over_world_uppercase')}{' '}
                </span>
              </p>
            </Col>
          </Row>
        </div>
      </BoxFrame>
      <ItemTitle
        items={[{ text: getFormatMessage('support_cloud_server_providers') }]}
        size="small"
        innerClassName={styles.providersName}
      />
      <BoxFrame className={styles.providersWrapper}>
        <Row gutter={[24, 24]}>
          <Col span={24} md={12} lg={8}>
            <div className={styles.providersItem}>
              <span className="icon-item">
                <img src={WordLIstIcon1} width={40} height={24} />
              </span>
              <span className="name-item">
                {getFormatMessage('amazon_web_services')}
              </span>
            </div>
          </Col>
          <Col span={24} md={12} lg={8}>
            <div className={styles.providersItem}>
              <span className="icon-item">
                <img src={WordLIstIcon2} width={31} height={24} />
              </span>
              <span className="name-item">
                {getFormatMessage('microsoft_azure')}
              </span>
            </div>
          </Col>
          <Col span={24} md={24} lg={8}>
            <div className={styles.providersItem}>
              <span className="icon-item">
                <img src={WordLIstIcon3} width={33} height={24} />
              </span>
              <span className="name-item">
                {getFormatMessage('tencent_cloud')}
              </span>
            </div>
          </Col>
        </Row>
      </BoxFrame>
    </div>
  );
};
