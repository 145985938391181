import { useEffect, useRef } from 'react';

/**
 *
 * @param callback
 * @param delay // ms
 */
export const useInterval = (callback: () => void, delay?: number | null) => {
  const savedCallback = useRef<Function>(() => {});

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!delay && delay !== 0) {
      return;
    }
    const id = setInterval(() => savedCallback.current(), delay || 1000);
    return () => clearInterval(id);
  }, [delay]);
};
