import { IString } from '../../model';

const state: IString<any> = {};

Object.assign(window, {
  cacheState: state,
});

/**
 * @method addCache
 * @description 添加缓存数据
 * @param key 存储的key键值名
 * @param data  存储的数据
 */
export function addCache<T>(key: string, data: T): Object {
  const id = key;
  Object.assign(state, {
    [id]: {
      ...state[id],
      ...data,
    },
  });
  return state;
}

export function getCache<T>(key?: string): null | T {
  if (key) {
    return state[key] as T;
  }
  return null;
}

export function deleteCache(key?: string) {
  if (key) {
    delete state[key];
    return;
  }
  Object.assign(state, {});
}
