import { messageError } from "../components/message";
import { withAPIAccount } from "../lib/http/api";
import { APIError, parsedResponse } from "../lib/http/http";
import { AccountType, IAPIUserInfo } from "../model";
import { API_PREFIX } from "./config";

// 用户信息
export const getUserInfo = () =>
  withAPIAccount(AccountType.auto, async (api) => {
    try {
      const url = `${API_PREFIX}/user/info`;
      const response = await api.commonRequest('GET', url);

      const res = await parsedResponse<IAPIUserInfo | null>(response, 'data');
      return res;
    } catch (error) {
      console.log('failed: getUserInfo', error);
      if (error instanceof APIError && error.responseStatus !== 401) {
        messageError(error.message);
      }
      return null;
    }
});
// 退出登录
export const getLogout = () =>
  withAPIAccount(AccountType.auto, async (api) => {
    try {
      const url = `${API_PREFIX}/user/logout`;
      const response = await api.commonRequest('POST', url);
      return !!(await parsedResponse(response, 'data'));
    } catch (err: any) {
      if (err instanceof APIError) {
        messageError(err?.apiError?.message);
      }
      return null;
    }
  });