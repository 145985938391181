/* eslint-disable @typescript-eslint/prefer-optional-chain */
import getFormatMessage from '../../locales/get-format-message';
import { BackEndCode } from '../../model';
// import { ErrCodeMsgMap } from '../consts/err-msg';
import { log } from '../log';
import { startTimer } from '../timing';
import { urlWithQueryString } from '../url';

export type HTTPMethod = 'GET' | 'POST' | 'PUT' | 'HEAD' | 'DELETE' | 'PATCH';

export interface IError {
  readonly message: string;
  readonly resource: string;
  readonly field: string;
}

export interface IAPIError {
  readonly errors?: IError[];
  readonly message?: string;
  readonly code?: number;
  readonly data?: any;
}

export interface IAPIUGitServerResponseBody {
  readonly ret: number;
  readonly msg: string;
}
const isDev = process.env.REACT_APP_ENV === 'development';
/** An error from getting an unexpected response to an API call. */
export class APIError extends Error {
  /** The error as sent from the API, if one could be parsed. */
  public readonly apiError: IAPIError | null;

  /** The HTTP response code that the error was delivered with */
  public readonly responseStatus: number;

  public constructor(response: Response, apiError: IAPIError | null) {
    let message;
    if (apiError && apiError.message) {
      message = apiError.message;

      const { errors } = apiError;
      const additionalMessages =
        errors && errors.map((e) => e.message).join(', ');
      if (additionalMessages) {
        message = `${message} (${additionalMessages})`;
      }
    } else {
      message = getFormatMessage('api_error_message', {
        params: {
          url: response.url,
          statusText: response.statusText,
          status: response.status,
        },
      });
    }

    super(message);

    this.responseStatus = response.status;
    this.apiError = apiError;
  }
}

/**
 * Deserialize the HTTP response body into an expected object shape
 *
 * Note: this doesn't validate the expected shape, and will only fail if it
 * encounters invalid JSON.
 */
async function deserialize<T>(response: Response): Promise<T> {
  try {
    const json = await response.json();
    return json as T;
  } catch (e) {
    const contentLength = response.headers.get('Content-Length') || '(missing)';
    log().warn(
      `deserialize: invalid JSON found at '${response.url}' - status: ${response.status}, length: '${contentLength}'`,
      e
    );
    throw e;
  }
}

const logErrorRecord = (error: Error) => {
  log().error(error);
};

/**
 * Make an API request.
 *
 * @param endpoint      - The API endpoint.
 * @param method        - The HTTP method.
 * @param path          - The path, including any query string parameters.
 * @param jsonBody      - The JSON body to send.
 * @param customHeaders - Any optional additional headers to send.
 * @param timeout       - The timeout of fetch request (ms).
 */
export function request(
  endpoint: string,
  method: HTTPMethod,
  path: string,
  jsonBody?: { [key: string]: any },
  customHeaders?: Object,
  timeout?: number
): Promise<Response> {
  const timingTimer = startTimer(`HTTP/${method}/${path}`);
  let url = `${isDev ? '' : endpoint}${path}`;
  let headers: any = {
    Accept: 'application/vnd.github.v3+json, application/json',
    'Content-Type': 'application/json',
  };

  headers = {
    ...headers,
    ...customHeaders,
  };

  const options = {
    headers,
    method,
    body: ['GET', 'HEAD'].includes(method)
      ? undefined
      : JSON.stringify(jsonBody),
  };

  // 请求统一json传值
  if (method === 'GET' && typeof jsonBody === 'object') {
    const reBody = JSON.parse(JSON.stringify(jsonBody));
    url = urlWithQueryString(url, reBody);
  }

  if (timeout) {
    let isTimeout = false;
    return new Promise((resolve, reject) => {
      const timer = setTimeout(() => {
        isTimeout = true;
        const error = new Error(
          `${timingTimer.actionId}, ${path}, Request Timeout!`
        );
        logErrorRecord(error);
        reject();
      }, timeout);
      fetch(url, options)
        .then((res) => {
          timingTimer.done();
          clearTimeout(timer);
          if (!isTimeout) {
            resolve(res);
          }
        })
        .catch((err: Error) => {
          timingTimer.done();
          const newError = {
            ...err,
            message: `${timingTimer.actionId} ${err.message}`,
          };
          logErrorRecord(newError);
          if (!isTimeout) {
            reject(newError);
          }
        });
    });
  }

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((res) => {
        timingTimer.done();
        if (res.status !== 200) {
          logErrorRecord(
            new Error(
              `${timingTimer.actionId}, ${res.status}, ${method}, ${path}`
            )
          );
        }
        resolve(res);
      })
      .catch((err: Error) => {
        timingTimer.done();
        const newError = {
          ...err,
          message: `${timingTimer.actionId} ${err.message}`,
        };
        logErrorRecord(newError);
        reject(newError);
      });
  });
}

// export function requestForm(
//   endpoint: string,
//   token: string | null,
//   method: HTTPMethod,
//   path: string,
//   jsonBody?: Object,
//   customHeaders?: Object
// ): Promise<Response> {
//   const params = { access_token: token as string }
//   const query = urlWithQueryString(path, token ? params : {})
//   let url = getAbsoluteUrl(endpoint, query)
//   if (
//     endpoint.includes('github.com') ||
//     isTapd(endpoint) ||
//     isCoding(endpoint)
//   ) {
//     url = getAbsoluteUrl(endpoint, path)
//   }
//   let headers: any = {
//     Accept:
//       'application/vnd.github.v3+json, application/json, application/x-www-form-urlencoded',
//     'Content-Type': 'application/x-www-form-urlencoded',
//     'User-Agent': getUserAgent(),
//   }

//   if (token) {
//     if (isTapd(endpoint) || isCoding(endpoint)) {
//       headers['Authorization'] = `Bearer ${token}`
//     } else {
//       headers['Authorization'] = `token ${token}`
//     }
//   }

//   headers = {
//     ...headers,
//     ...customHeaders,
//   }

//   const options = {
//     headers,
//     method,
//     body: jsonBody && paramsToStr(jsonBody),
//   }

//   return new Promise((resolve, reject) => {
//     return fetch(url, options)
//       .then(res => {
//         if (res.status !== 200) {
//           mountainsProfilerHttp(res, method, path, url, jsonBody)
//         }
//         resolve(res)
//       })
//       .catch(err => {
//         mountainsProfilerHttp(err, method, path, url, jsonBody)
//         reject(err)
//       })
//   })
// }

// export function requestFormData(
//   endpoint: string,
//   token: string | null,
//   method: HTTPMethod,
//   path: string,
//   body: {
//     fileName: string
//     file: Blob
//   },
//   customHeaders?: Object
// ): Promise<Response> {
//   const params = { access_token: token as string }
//   const query = urlWithQueryString(path, params)
//   let url = getAbsoluteUrl(endpoint, query)
//   if (endpoint.includes('github.com') || isTapd(endpoint)) {
//     url = getAbsoluteUrl(endpoint, path)
//   }
//   let headers: any = {
//     Accept: 'application/vnd.github.v3+json, multipart/form-data',
//     'User-Agent': getUserAgent(),
//   }

//   if (token) {
//     if (isTapd(endpoint) || isCoding(endpoint)) {
//       headers['Authorization'] = `Bearer ${token}`
//     } else {
//       headers['Authorization'] = `token ${token}`
//     }
//   }

//   headers = {
//     ...headers,
//     ...customHeaders,
//   }

//   const data = new FormData()
//   data.append(body.fileName, body.file)

//   const options = {
//     headers,
//     method,
//     body: data,
//   }

//   return fetch(url, options)
// }

/**
 * If the response was OK, parse it as JSON and return the result. If not, parse
 * the API error and throw it.
 */
// export async function parsedResponse<T>(response: Response): Promise<T> {
//   if (response.ok) {
//     return deserialize<T>(response)
//   } else {
//     let apiError: IAPIError | null
//     try {
//       apiError = await deserialize<IAPIError>(response)
//     } catch (e) {
//       throw new APIError(response, null)
//     }

//     throw new APIError(response, apiError)
//   }
// }

// 后台提供服务，建议统一json格式: {ret: number, msg: string, data: T}
// 当ret = 0时，返回参数建议放置于data下，目的可以定义类型
// // tapd status === 1 标识成功

export async function parsedResponse<T>(
  response: Response,
  key?: string
): Promise<T> {
  if (response.ok) {
    const json = await response.json();
    if (
      json.ret === 0 ||
      json.status === 1 ||
      json.code === 0 ||
      json.result === true ||
      BackEndCode[json.code]
    ) {
      const newKey = key || 'data';
      if (newKey) {
        if (json[newKey] !== undefined) {
          return json[newKey] as T;
        }
        return json;
      }
    }
    const codeAsString = json.code.toString();
    throw new APIError(response, {
      // message: ErrCodeMsgMap[json.code] || json.msg,
      message: getFormatMessage(codeAsString) || json.msg,
      code: json.code,
      data: json.data,
    });
  }

  let apiError: IAPIError | null;
  try {
    apiError = await deserialize<IAPIError>(response);
  } catch (e) {
    throw new APIError(response, null);
  }

  throw new APIError(response, apiError);
}

/*
export async function parsedResponseToBlob(
  response: Response
): Promise<Blob | null> {
  if (response.ok) {
    const json = response.blob();
    return json;
  }

  return null;
}

export function paramsToStr(params: any) {
  return Object.keys(params)
    .filter((key) => params[key])
    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
    .join('&');
}
*/
