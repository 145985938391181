import { message } from 'antd';
import warnGreen from '../assets/images/warn-green.png';
import warnYellow from '../assets/images/warn-yellow.png';
import messageErrorIcon from '../assets/images/message-error.png';
import messageSuccessIcon from '../assets/images/message-success.png';

message.config({
  duration: 3,
  maxCount: 1,
});
export const messageInfo = (msg: any) => {
  message.info({
    content: msg,
    icon: <img src={warnGreen} />,
  });
};

export const messageWarning = (msg: any) => {
  message.warning({
    content: msg,
    icon: <img src={warnYellow} />,
  });
};

export const messageSuccess = (msg: any) => {
  message.success({
    content: msg,
    icon: <img src={messageSuccessIcon} />,
  });
};

export function messageError(msg: any, key?: string | number) {
  message.error({
    content: msg,
    key,
    icon: <img src={messageErrorIcon} />,
  });
}
