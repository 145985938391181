export interface IString<V> {
  [x: string]: V;
}

export interface INumber<V> {
  [x: number]: V;
}

export enum TimeType {
  day = 'day',
  week = 'week',
  month = 'month',
}

export enum RouterNotFoundSource {
  'Project',
}

export enum FormatTimeUnit {
  Year = 'y',
  Month = 'm',
  Day = 'd',
  Hour = 'h',
  Min = 'm',
  Sec = 's',
}

export enum UnitEnum {
  Percent = '%',
  Min = 'min',
  Piece = '个',
  Second = '次',
  People = '人',
  PieceEn = 'Piece',
  G = 'G',
  S = 's',
  M = 'M',
}

export interface IAPIresponse {
  readonly code?: number;
  readonly msg?: string;
  readonly time?: number;
  readonly data?: any;
  readonly result?: {
    readonly task_id: number;
  };
}

export interface IAPIresponseTask {
  readonly code?: number;
  readonly msg?: string;
  readonly time?: number;
  readonly data?: any;
  readonly result?: readonly {
    readonly status?: number;
    readonly task_index?: number;
  }[];
}

export interface IValue {
  readonly key: string;
  readonly value: any;
}

export interface IPagination {
  readonly current: number; // 当前页数
  readonly total: number; // 数据总数
  readonly pageSize: number; // 每页条数
}
