import moment from 'moment';
import { FormatTimeUnit } from '../model';

export const getFormatTime = (date?: Date) => {
  if (!date) return '';
  const strDate = date.toString();
  if (strDate.indexOf('0000-00-00') !== -1) {
    return '--';
  }
  // 格式需要转化
  const formattedDate = strDate.replace('T', ' ').replace('.000Z', '');
  return formattedDate;
};

const timeFormat = (num: number) => {
  if (num < 10) {
    return `0${num}`;
  }
  return num;
};

const generate = () => {
  const time = new Date();
  const getMilliseconds = time.getMilliseconds();
  const day = `${time.getFullYear()}-${timeFormat(
    time.getMonth() + 1
  )}-${timeFormat(time.getDate())}`;
  const hours = time.toTimeString().slice(0, 8);
  return {
    timeStamp: +time,
    day,
    hours,
    seconds: getMilliseconds,
  };
};

export const getTimeSeconds = () => {
  const time = generate();
  return `${time.day} ${time.hours}`;
};

export const getTimeFull = () => {
  const time = generate();
  return `${time.day} ${time.hours} ${time.seconds}`;
};

export const getLastDay = () => {
  const curTime = new Date();
  const time = new Date(curTime.getTime() - 24 * 60 * 60 * 1000);
  return `${time.getFullYear()}-${timeFormat(time.getMonth() + 1)}-${timeFormat(
    time.getDate()
  )}`;
};

export const getDay = () => {
  const time = new Date();
  return `${time.getFullYear()}-${timeFormat(time.getMonth() + 1)}-${timeFormat(
    time.getDate()
  )}`;
};

export function formatDate(date: string) {
  if (date.length === 8) {
    return moment(date).format('YYYY-MM-DD');
  }

  return date;
}

export function formatDateCustom(date: string | number, type?: any) {
  if (type === 'month') return moment(date).format('MM');
  return moment(date).format('MM-DD');
}

export function formatDateType(date: string, type?: string) {
  if (type === 'month') return moment(date).format('YYYY-MM');
  return moment(date).format('MM-DD');
}
export function formatDateMonth(date: string) {
  if (date.length === 6) {
    return moment(date).format('YYYY-MM');
  }

  return date;
}

// 因为不清楚上面两个方法在使用中的具体逻辑 所以不做复用
export function formatDayOrMonth(date: string) {
  if (date.length === 8) {
    return moment(date).format('YYYY-MM-DD');
  }
  if (date.length === 6) {
    return moment(date).format('YYYY-MM');
  }

  return date;
}

export function briefDate(date: string, type?: any, i?: number) {
  if (type === 'day') {
    return moment(date).format('MM-DD');
  }
  if (type === 'week') {
    if (i || i === 0) {
      return `近${i + 1}周`;
    }
  }

  if (type === 'month') {
    return moment(date).format('MM');
  }
  return date;
}

export function formatDateYtoM(date: string) {
  return moment(date).format('YYYY年MM月');
}
/**
 * @param text 需要转换的文本或者是数值，数值单位是s
 * @param precision 保留的小数位
 */
export const formatAsUnit = (
  text: number | string,
  precision = 2,
  unit: FormatTimeUnit = FormatTimeUnit.Hour
) => {
  const type = typeof text;
  if (type === 'string') {
    return `${text} ${unit}`;
  }
  if (type === 'number') {
    let res: number = +text;
    switch (unit) {
      case FormatTimeUnit.Year:
        res = res / (365 * 24 * 3600);
        break;
      case FormatTimeUnit.Month:
        res = res / (30 * 24 * 3600);
        break;
      case FormatTimeUnit.Day:
        res = res / (24 * 3600);
        break;
      case FormatTimeUnit.Hour:
        res = res / 3600;
        break;
      case FormatTimeUnit.Min:
        res = res / 60;
        break;
    }
    return `${res.toFixed(precision)} ${unit}`;
  }
  return text;
};

// 格式化时间为最近 时分秒天,单位为ms
export const formatAsLatest = (time: number, unit = 'en') => {
  const second = time / 1000;
  const MIN = 60;
  const HOUR = MIN * 60;
  const DAY = HOUR * 24;

  const unitText: {
    [key: string]: { s: string; min: string; h: string; d: string };
  } = {
    en: {
      s: 's',
      min: 'min',
      h: 'h',
      d: 'd',
    },
    zh: {
      s: '秒',
      min: '分钟',
      h: '小时',
      d: '天',
    },
  };

  if (second < MIN) {
    return `${second}s`;
  }
  if (second < HOUR) {
    const min = Math.round(second / MIN);
    return `${min}${unitText[unit].min}`;
  }
  if (second < DAY) {
    const hour = Math.floor(second / HOUR);
    const min = Math.round((second - hour * HOUR) / MIN);
    return `${hour}${unitText[unit].h}${min}${unitText[unit].min}`;
  }
  const day = Math.floor(second / DAY);
  const hour = Math.floor((second - day * DAY) / HOUR);
  const min = Math.round((second - day * DAY - hour * HOUR) / MIN);
  return `${day}${unitText[unit].d}${hour}${unitText[unit].h}${min}${unitText[unit].min}`;
};
