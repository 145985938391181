import { getConfig } from '../config';
import { aegis } from './aegis';
import { getTimeFull } from './time';
const isProd = process.env.REACT_APP_ENV === 'production';
const win: any = window;
export class Log {
  public info(...rest: any) {
    console.log(`${this.prefix}INFO|`, ...rest);
    aegis.info(`${this.prefix}INFO|`, ...rest);
  }

  public warn(...rest: any) {
    console.warn(`${this.prefix}WARN|`, ...rest);
    aegis.info(`${this.prefix}WARN|`, ...rest);
  }

  public error(...rest: any) {
    console.error(`${this.prefix}ERROR|`, ...rest);
    aegis.error(`${this.prefix}ERROR|`, ...rest);
  }

  private get prefix() {
    const config = getConfig();
    return `[${getTimeFull()}]|${win.userInfo?.staff_name || '---'}|${
      config.appID
    }|${config.env}|`;
  }
}

const instance: Log | null = null;
export const log = () => {
  if (!isProd) {
    return {
      info: () => {},
      error: () => {},
      warn: () => {},
    };
  }
  if (instance) {
    return instance;
  }
  return new Log();
};
