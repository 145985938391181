import { LayoutDefault } from '../../router/templates/default';
import { BannerBox } from '../components/banner-box';
import { IntroduceItem } from '../components/introduce-item';
import { DevelopersBox } from './developers-box';
import { CreativityBox } from './creativity-box';
import { StatementBox } from './statement-box';
import { WordBox } from '../components/word-box';
import { ItemTitle } from '../common/item-title';
import { TryFreeBox } from '../common/try-free-box';
import imgSrc1 from '../../assets/images/c-introduce-item-img-01.png';
import imgSrc2 from '../../assets/images/c-introduce-item-img-02.png';
import imgSrc3 from '../../assets/images/c-introduce-item-img-03.png';
import imgSrc4 from '../../assets/images/c-introduce-item-img-04.png';
import imgSrc5 from '../../assets/images/c-introduce-item-img-05.png';
import { ReactComponent as Icon1_1 } from '../../assets/svg/c-home-introduce-item-icon-1-1.svg';
import { ReactComponent as Icon1_2 } from '../../assets/svg/c-home-introduce-item-icon-1-2.svg';
import { ReactComponent as Icon1_3 } from '../../assets/svg/c-home-introduce-item-icon-1-3.svg';
import { ReactComponent as Icon2_1 } from '../../assets/svg/c-home-introduce-item-icon-2-1.svg';
import { ReactComponent as Icon2_2 } from '../../assets/svg/c-home-introduce-item-icon-2-2.svg';
import { ReactComponent as Icon2_3 } from '../../assets/svg/c-home-introduce-item-icon-2-3.svg';
import { ReactComponent as Icon3_1 } from '../../assets/svg/c-home-introduce-item-icon-3-1.svg';
import { ReactComponent as Icon3_2 } from '../../assets/svg/c-home-introduce-item-icon-3-2.svg';
import { ReactComponent as Icon3_3 } from '../../assets/svg/c-home-introduce-item-icon-3-3.svg';
import { ReactComponent as Icon4_1 } from '../../assets/svg/c-home-introduce-item-icon-4-1.svg';
import { ReactComponent as Icon4_2 } from '../../assets/svg/c-home-introduce-item-icon-4-2.svg';
import { ReactComponent as Icon5_1 } from '../../assets/svg/c-home-introduce-item-icon-5-1.svg';
import { ReactComponent as Icon5_2 } from '../../assets/svg/c-home-introduce-item-icon-5-2.svg';
import { ReactComponent as Icon5_3 } from '../../assets/svg/c-home-introduce-item-icon-5-3.svg';
import styles from './style.module.scss';
import getFormatMessage from '../../locales/get-format-message';
import classNames from 'classnames';

export const HomeRoute = () => {
  return (
    <LayoutDefault>
      <>
        <BannerBox/>
        <ItemTitle
          className={styles.detaileBoxTitle}
          items={[
            { text: getFormatMessage('in_game') },
            { text: getFormatMessage('is_a_adaptable') },
            { text: getFormatMessage('gaming_backend'), textColor: 'primary' },
          ]}
        />
        <IntroduceItem
          tagName={getFormatMessage('multiplayer')}
          itemTitle={getFormatMessage('crafting_dynamic_desc')}
          itemTitleExtraInfo={getFormatMessage('harness_power_our_core_desc')}
          // itemButtonText={getFormatMessage('explore_multiplayer')}
          // itemButtonHref="#"
          radioName="1"
          tagColor="orange"
          sideImgSrc={imgSrc1}
          imgWidth={617}
          imgHeight="auto"
          className={classNames(styles.introduceItemHome, styles.is01)}
          items={[
            {
              listIcon: <Icon1_1 width={28} height={24} />,
              listTitle: getFormatMessage('player_centric'),
              listInfo: `${getFormatMessage('supports_login_matchmaking_desc1')}<br>${getFormatMessage('supports_login_matchmaking_desc2')}<br>${getFormatMessage('supports_login_matchmaking_desc3')}`,
              defaultChecked: true,
            },
            {
              listIcon: <Icon1_2 width={28} height={24} />,
              listTitle: getFormatMessage('flexible_matchmaking'),
              listInfo: `${getFormatMessage('supports_custom_matchmaking_desc1')}<br>${getFormatMessage('supports_custom_matchmaking_desc2')}`,
            },
            {
              listIcon: <Icon1_3 width={28} height={24} />,
              listTitle: getFormatMessage('highly_scalable'),
              listInfo: `${getFormatMessage('expand_game_backend_desc1')}<br>${getFormatMessage('expand_game_backend_desc2')}`,
            },
          ]}
          id="multiplayer"
        />
        <IntroduceItem
          even
          hasBg
          tagName={getFormatMessage('interactive_social')}
          itemTitle={getFormatMessage('enabling_players_from_desc')}
          itemTitleExtraInfo={getFormatMessage('create_game_social_layer_desc')}
          // itemButtonText={getFormatMessage('explore_multiplayer')}
          // itemButtonHref="#"
          radioName="2"
          tagColor="green"
          sideImgSrc={imgSrc2}
          imgWidth={673}
          imgHeight="auto"
          className={classNames(styles.introduceItemHome, styles.is02)}
          items={[
            {
              listIcon: <Icon2_1 width={28} height={24} />,
              listTitle: getFormatMessage('cross_platform'),
              listInfo: `${getFormatMessage('supports_cross_platform_desc1')}<br>${getFormatMessage('supports_cross_platform_desc2')}<br>${getFormatMessage('supports_cross_platform_desc3')}`,
              defaultChecked: true,
            },
            {
              listIcon: <Icon2_2 width={28} height={24} />,
              listTitle: getFormatMessage('real_time_interaction'),
              listInfo: `${getFormatMessage('high_quality_text_and_voice_desc1')}<br>${getFormatMessage('high_quality_text_and_voice_desc2')}<br>${getFormatMessage('high_quality_text_and_voice_desc3')}`,
            },
            {
              listIcon: <Icon2_3 width={28} height={24} />,
              listTitle: getFormatMessage('competitive_ranking'),
              listInfo: `${getFormatMessage('scalable_distributed_desc1')}<br>${getFormatMessage('scalable_distributed_desc2')}`,
            },
          ]}
          id="social"
        />
        <IntroduceItem
          tagName={getFormatMessage('content_update')}
          itemTitle={getFormatMessage('all_platform_universal_desc')}
          itemTitleExtraInfo={getFormatMessage('develop_a_universal_desc')}
          // itemButtonText={getFormatMessage('explore_content')}
          // itemButtonHref="#"
          radioName="3"
          tagColor="blue"
          sideImgSrc={imgSrc3}
          imgWidth={651}
          imgHeight="auto"
          className={classNames(styles.introduceItemHome, styles.is03)}
          items={[
            {
              listIcon: <Icon3_1 width={28} height={24} />,
              listTitle: getFormatMessage('leading_infrastructure'),
              listInfo: `${getFormatMessage('server_nods_cover_desc1')}<br>${getFormatMessage('server_nods_cover_desc2')}`,
              defaultChecked: true,
            },
            {
              listIcon: <Icon3_2 width={28} height={24} />,
              listTitle: getFormatMessage('game_appropriate'),
              listInfo: `${getFormatMessage('provides_unified_game_version_desc1')}<br>${getFormatMessage('provides_unified_game_version_desc2')}`,
            },
            {
              listIcon: <Icon3_3 width={28} height={24} />,
              listTitle: getFormatMessage('rapid_dynamic_deployment'),
              listInfo: `${getFormatMessage('cloud_based_simple_storage_desc1')}<br>${getFormatMessage('cloud_based_simple_storage_desc2')}`,
            },
          ]}
          id="content"
        />
        <IntroduceItem
          even
          hasBg
          tagName={getFormatMessage('economic_system')}
          itemTitle={getFormatMessage('build_long_term_operational_desc')}
          itemTitleExtraInfo={getFormatMessage(
            'create_game_social_different_desc'
          )}
          // itemButtonText={getFormatMessage('explore_economy')}
          // itemButtonHref="#"
          radioName="4"
          tagColor="yellow"
          sideImgSrc={imgSrc4}
          imgWidth={647}
          imgHeight="auto"
          items={[
            {
              listIcon: <Icon4_1 width={28} height={24} />,
              listTitle: getFormatMessage('comprehensive_economic_ecosystem'),
              listInfo: `${getFormatMessage('a_complete_economic_system_desc1')}<br>${getFormatMessage('a_complete_economic_system_desc2')}`,
              defaultChecked: true,
            },
            {
              listIcon: <Icon4_2 width={28} height={24} />,
              listTitle: getFormatMessage('flexible_asset_management'),
              listInfo: `${getFormatMessage('in_game_item_and_virtual_desc1')}<br>${getFormatMessage('in_game_item_and_virtual_desc2')}<br>${getFormatMessage('in_game_item_and_virtual_desc3')}`,
            },
          ]}
          id="economy"
          className={classNames(styles.introduceItemHome, styles.is04)}
        />
        <IntroduceItem
          tagName={getFormatMessage('data_analysis')}
          itemTitle={getFormatMessage('creating_mature_data')}
          itemTitleExtraInfo={getFormatMessage('build_data_analysis_desc')}
          // itemButtonText={getFormatMessage('explore_analytics')}
          // itemButtonHref="#"
          radioName="5"
          tagColor="purple"
          sideImgSrc={imgSrc5}
          imgWidth={660}
          imgHeight="auto"
          className={classNames(styles.introduceItemHome, styles.is05)}
          items={[
            {
              listIcon: <Icon5_1 width={28} height={24} />,
              listTitle: getFormatMessage('mature_analytics_system'),
              listInfo: `${getFormatMessage('easily_understand_your_name_desc1')}<br>${getFormatMessage('easily_understand_your_name_desc2')}`,
              defaultChecked: true,
            },
            {
              listIcon: <Icon5_2 width={28} height={24} />,
              listTitle: getFormatMessage('efficient_self_service_desc'),
              listInfo: `${getFormatMessage('convenient_and_efficient_data_desc1')}<br>${getFormatMessage('convenient_and_efficient_data_desc2')}`,
            },
            {
              listIcon: <Icon5_3 width={28} height={24} />,
              listTitle: getFormatMessage('intelligent_performance_monitoring'),
              listInfo: `${getFormatMessage('fine_grained_event_desc1')}<br>${getFormatMessage('fine_grained_event_desc2')}`,
            },
          ]}
          id="analytics"
        />
        <DevelopersBox />
        <CreativityBox />
        <StatementBox />
        <WordBox />
        <TryFreeBox
          className={styles.homeTryFreeBox}
          defatultText={getFormatMessage('try_for_free')}
          title={getFormatMessage('ready_to_build_game')}
          detailItems={[
            {
              text: getFormatMessage('get_started_now'),
            },
            {
              text: getFormatMessage('let_innovate_experiences_desc'),
            },
          ]}
        />
      </>
    </LayoutDefault>
  );
};
