import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getCurrentLangText } from '../../../lib';
import getFormatMessage from '../../../locales/get-format-message';

import notificationIcon from '../../../assets/svg/notification-filled.svg';
import closeIcon from '../../../assets/svg/notice-close-icon.svg';
import styles from './index.module.scss';
import headerStyles from '../header/style.module.scss';
import { INews } from '../../../model/news';
import { getUpgradeNews } from '../../../api/news';
import {
  getNumber,
  setNumber,
} from '../../../lib/local-storage/interface-base';

const LAST_CLOSE_NOTICE_KET = 'ingame_website_last_close_notification_id';
const WEBSITE_PAGE_TYPE = 2;

interface IProps {
  onDisplayChange?: (show: boolean) => void;
}

export const GlobalNotification = (props: IProps) => {
  const location = useLocation();
  const [news, setNews] = useState<INews>();

  useEffect(() => {
    init();
  }, [location]);

  useEffect(() => {
    const links = document.querySelectorAll(
      `.${styles.globalNotification} a`
    ) as NodeListOf<HTMLElement>;
    links.forEach((link) => {
      link.setAttribute('target', '_blank');
    });
    addPlaceholder();
    setHeaderStyle();
    window.addEventListener('scroll', setHeaderStyle);
    return () => {
      window.removeEventListener('scroll', setHeaderStyle);
    };
  }, [news]);

  const setHeaderStyle = useCallback(() => {
    const headerElement = document.querySelector(
      `.${headerStyles.headerNav}`
    ) as HTMLElement;
    const isScrollDown = !!document.querySelector(
      `.${headerStyles.isScrollDown}`
    );
    const noticeDom = document.querySelector(
      `.${styles.globalNotification}`
    ) as HTMLElement;

    if (isScrollDown && noticeDom) {
      const top = noticeDom.offsetHeight;
      headerElement.style.top = `${top}px`;
      return;
    }
    headerElement.style.top = '';
  }, []);

  const addPlaceholder = () => {
    if (!news) {
      document.querySelector('.upgrade-notification-placeholder')?.remove();
      return;
    }
    const noticeDom = document.querySelector(
      `.${styles.globalNotification}`
    ) as HTMLElement;
    if (
      noticeDom.parentElement?.querySelector(
        '.upgrade-notification-placeholder'
      )
    ) {
      return;
    }
    const height = noticeDom.offsetHeight;
    const placeholderDom = document.createElement('div');
    placeholderDom.className = 'upgrade-notification-placeholder';
    placeholderDom.style.height = `${height}px`;
    noticeDom.parentNode?.insertBefore(placeholderDom, noticeDom);
  };

  const init = async () => {
    const res = await getUpgradeNews({
      Module: String(WEBSITE_PAGE_TYPE),
    });
    const newsList = res?.list;
    const firstItem = newsList?.[0];
    const lastCloseId = getNumber(LAST_CLOSE_NOTICE_KET);
    if (lastCloseId === firstItem?.id) {
      return;
    }

    if (firstItem) {
      setNews(firstItem);
      props.onDisplayChange?.(true);
    }
  };

  const saveCloseHistory = () => {
    if (!news) {
      return;
    }
    setNumber(LAST_CLOSE_NOTICE_KET, news.id);
  };

  const close = () => {
    saveCloseHistory();
    setNews(undefined);
    props.onDisplayChange?.(false);
  };

  if (!news) {
    return null;
  }

  return (
    <div
      className={classNames(styles.globalNotification, 'global-notice-console')}
    >
      <div className={styles.notificationItem}>
        <span className={styles.title}>
          <img src={notificationIcon} width={16} height={16} />
          {getCurrentLangText(news?.Title) ||
            `${getFormatMessage('ingame_upgrade_notification')}`}
        </span>
        <span
          className={styles.content}
          dangerouslySetInnerHTML={{
            __html: getCurrentLangText(news?.Content),
          }}
        ></span>
      </div>

      <img className={styles.closeIcon} src={closeIcon} onClick={close} />
    </div>
  );
};
