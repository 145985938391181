import LangComponents from './en-us/components';
import LangGetStarted from './en-us/get-started';
import LangHome from './en-us/home';
import LangErrCodeMsg from './en-us/err-code-msg';

const common = {
  get_started:'Get started',
  get_started_uppercase:'Get Started',
  dev_portal: 'Dev Portal',
  products_home:'Products',
  products: 'Products',
  documentation:'Documentation',
  download_center:'Download Center',
  sign_out_label: 'Sign Out',
  about_us:'About Us',
  account_label: 'Account',
  contact_us:'Contact Us',
  footer_copyright:'©2021-2024 Proxima Beta Pte. Ltd. All rights reserved.',
  privacy_policy:'Privacy Policy',
  terms_of_service:'Terms of Service',
  cookies_policy:'Cookies Policy',
  oss_information:'OSS Information',
  multiplayer: 'Multiplayer',
  content:'Content',
  social:'Social',
  economy:'Economy',
  engage:'Engage',
  analytics:'Analytics',
  try_for_free:'TRY FOR FREE!',

  create: 'Create',
  delete: 'Delete',
  submit: 'Submit',
  cancel: 'Cancel',
  close: 'Close',
  save: 'Save',
  confirm_to: 'Confirm',
  site_switch_cn: 'Go to Chinese Site',
  site_switch_en: 'Go to International Site',
  site_switch_woa: 'Go to Woa Site',
  site_switch_cn_desc:
    'You are about to go to the InGame Chinese Site. Do you want to continue?',
  site_switch_en_desc:
    'You are about to go to the InGame International Site. Do you want to continue?',
  site_switch_woa_desc:
    'You are about to go to the InGame Woa Site. Do you want to continue?',
  // 中文,英文
  // china:'中文版',
  // english:'English',
  ...LangComponents,
  ...LangGetStarted,
  ...LangHome,
  ...LangErrCodeMsg,
};

// eslint-disable-next-line no-restricted-syntax
export default Object.assign(common);

