import Cookies from 'js-cookie';
import { BASE_LOGIN_COOKIE_KEY, getLoginCookieKey } from '../../../config';
import { getDomain } from '../../../lib';

export const setCookieToken = (ticket: string) => {
  const date = new Date();
  const hour = 11 * 60 * 60 * 1000; // 11小时过期
  const expireTime = Number(Date.parse(String(date)) + hour);

  const opt = {
    path: '/',
    expires: new Date(expireTime),
    domain: getDomain(),
  };
  Cookies.set(BASE_LOGIN_COOKIE_KEY, ticket, opt);
  Cookies.set(getLoginCookieKey(), ticket, opt);
};

export const removeCookieToken = () => {
  const domain = getDomain();
  const opt = {
    path: '/',
    domain,
  };
  Cookies.remove(BASE_LOGIN_COOKIE_KEY, opt);
  Cookies.remove(getLoginCookieKey(), opt);
};

export const getCookieToken = () => {
  return Cookies.get(getLoginCookieKey()) || '';
};    
// export function checkUserLogin() {
//   const ticket = getLoginCookie('INTL_GCLOUD_TICKET');// eslint-disable-line
//   if (ticket === "") {
//       return false;
//   }
//   return ticket;
// }
