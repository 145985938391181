import styles from './style.module.scss';
import { ReactComponent as Logo } from '../../../assets/svg/logo.svg';
import { Row, Col, Space } from 'antd';
import { BoxFrame } from '../../common/box-frame';
import getFormatMessage from '../../../locales/get-format-message';
import { getLocaleLanguage, LanguageType } from '../../../lib';
import { getConfig } from '../../../config';
import { IAppLinkConfig } from '../../../model';
import { jumpLogin } from '../../../lib/jump-website';
import { getLoginToken } from '../../lib';

export const FooterComponent = () => {
  const currentStoreLanguage = getLocaleLanguage() || LanguageType.en_US;
  const copyRight = getConfig()?.copyRight;
  const userURL = getConfig()?.userURL;
  const consoleURL = getConfig()?.consoleURL;
  const { copyRightText, protocolLinks, filingCaseNumber, filingLink } =
    copyRight;
  const documentConfig = getConfig()?.documentConfig;
  const ingameModule = documentConfig.modules.find(
    (module) => module.key === 'INGAME'
  );
  const handleDocumentationClick = () => {
    const pid = ingameModule?.pid[currentStoreLanguage];
    const ticket = getLoginToken();
    if (ticket) {
      if (consoleURL && pid) {
        const url = `${consoleURL}/doc/detail/${pid}`;
        window.open(url, '_blank');
      }
    } else {
      jumpLogin();
    }
  };
  return (
    <BoxFrame className={styles.footerComponent}>
      <Row gutter={[48, 48]}>
        <Col flex={1}>
          <Space align="start" size={128} wrap className={styles.footerSpace}>
            <dl className={styles.footerListBox}>
              <dt>{getFormatMessage('products')}</dt>
              <dd>
                <a href="/#multiplayer">{getFormatMessage('multiplayer')}</a>
              </dd>
              <dd>
                <a href="/#social">{getFormatMessage('social')}</a>
              </dd>
              <dd>
                <a href="/#content">{getFormatMessage('content')}</a>
              </dd>
              <dd>
                <a href="/#economy">{getFormatMessage('economy')}</a>
              </dd>
              {/* <dd>
                <a href="/#engage">{getFormatMessage('engage')}</a>
              </dd> */}
              <dd>
                <a href="/#analytics">{getFormatMessage('analytics')}</a>
              </dd>
            </dl>
            <dl className={styles.footerListBox}>
              <dt>{getFormatMessage('resources')}</dt>
              {/* <dd>
                <a href="">{getFormatMessage('roadmap')}</a>
              </dd> */}
              <dd>
                <span onClick={handleDocumentationClick}>
                  {getFormatMessage('docs')}
                </span>
              </dd>
            </dl>
            <dl className={styles.footerListBox}>
              <dt>{getFormatMessage('support')}</dt>
              <dd>
                <a href="/get-started">
                  {getFormatMessage('get_started_uppercase')}
                </a>
              </dd>
              {/* <dd>
                <a href="">{getFormatMessage('about')}</a>
              </dd> */}
            </dl>
          </Space>
        </Col>
        <Col>
          <Space>
            <dl className={styles.footerListBox}>
              <dt>{getFormatMessage('we_are_online')}</dt>
              <dd>
                <a
                  href={`${userURL}/contact?ingame_language=${currentStoreLanguage}`}
                >
                  {getFormatMessage('contact_us')}
                </a>
              </dd>
              <dd className="list-extra-text">
                <span className="list-disable-click">
                  {getFormatMessage('contact_for_product')}
                </span>
              </dd>
            </dl>
          </Space>
        </Col>
        <Col span={24}>
          <Row gutter={[24, 24]} align="middle">
            <Col>
              <span className={styles.footerLogo}>
                <Logo width={80} height={23} />
              </span>
            </Col>
            <Col>
              <p className={styles.footerCopyRight}>
                {copyRightText}
                <span
                  className={filingLink ? styles.link : ''}
                  onClick={() => window.open(filingLink, '_blank')}
                >
                  {filingCaseNumber}
                </span>
              </p>
            </Col>
            <Col flex={1}>
              <p className={styles.footerEndLink}>
                {protocolLinks[currentStoreLanguage]
                  ? protocolLinks[currentStoreLanguage].map(
                      (link: IAppLinkConfig) => {
                        let processedUrl = '';
                        if (link.url === '') {
                          processedUrl = '';
                        } else if (link.url.includes('.com')) {
                          processedUrl = link.url;
                        } else {
                          processedUrl = `${consoleURL}${link.url}`;
                        }
                        return (
                          <a href={processedUrl} target="_blank">
                            {link.label}
                          </a>
                        );
                      }
                    )
                  : null}
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </BoxFrame>
  );
};
