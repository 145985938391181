// 任务状态
export enum RepoCrontabStatus {
  Initialization, // 初始化
  Running, // 运行中
  Disabled, // 禁用
  Delete, // 删除
}

// 执行频率，interval一直间隔执行, number: 设定执行次数，需要大于0，最少执行1次
export type CrontabFrequency = 'interval' | number;

export interface ICrontabBasic {
  readonly id: string;
  readonly name: string; // 任务名称
  readonly status: RepoCrontabStatus; // 任务状态
  readonly frequency: CrontabFrequency;

  readonly lastSyncTime?: string; // 最后同步时间
  readonly record?: ICrontabRecord[]; // 操作记录
}

export const TIME_LEN = 5;
export const TIME_PLACEHOLDER = '*';

export enum CrontabExecStatus {
  Running,
  Success,
  Failed,
}

export interface ICrontabRecord {
  readonly id?: number;
  readonly log?: string; // 错误信息
  readonly status?: CrontabExecStatus;
  readonly timeStart?: string;
  readonly timeEnd?: string;
}
