import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import { BoxFrame } from '../../common/box-frame';
import { ItemTitle } from '../../common/item-title';
import { ReactComponent as IconWindows } from '../../../assets/svg/g-icon-windows.svg';
import { ReactComponent as Icon3D } from '../../../assets/svg/g-icon-3d.svg';
import { ReactComponent as IconAndroid } from '../../../assets/svg/g-icon-android.svg';
import { ReactComponent as IconiOS } from '../../../assets/svg/g-icon-ios.svg';
import { ReactComponent as IconPlayStation } from '../../../assets/svg/g-icon-play-station.svg';
import { ReactComponent as IconUnity } from '../../../assets/svg/g-icon-unity.svg';
import { ReactComponent as IconWord } from '../../../assets/svg/g-icon-word.svg';
import { ReactComponent as IconSteam } from '../../../assets/svg/g-icon-steam.svg';
import { ReactComponent as IconNatiiveEngine } from '../../../assets/svg/g-icon-natiive-engine.svg';
import getFormatMessage from '../../../locales/get-format-message';
import classNames from 'classnames';

export const CreativityBox = () => {
  const [showStartVideo, setShowStartVideo] = useState<boolean>(false);
  useEffect(() => {
    // 定义滚动事件处理函数
    const handleScroll = () => {
      // 获取滚动条的高度
      const scrollHeight = window.scrollY;
      scrollHeight > 4800 ? setShowStartVideo(true) : setShowStartVideo(false);
    };

    // 在组件挂载时添加滚动事件监听器
    window.addEventListener('scroll', handleScroll);

    // 在组件卸载时移除滚动事件监听器
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // 空数组作为第二个参数表示仅在组件挂载和卸载时执行一次
  return (
    <div className={styles.creativityBox}>
      <ItemTitle
        type="inline"
        items={[
          { text: getFormatMessage('unleashing') },
          { text: getFormatMessage('creativity'), textColor: 'primary' },
          { text: getFormatMessage('unbounded') },
        ]}
        itemsExtra={[
          {
            text: getFormatMessage('craft_perfect_gameplay'),
          },
          { text: getFormatMessage('that_why_services_intergrate') },
          { text: getFormatMessage('allowing_developers_desc') },
          {
            text: getFormatMessage('let_create_immersive'),
          },
        ]}
      />
      {/* 火箭动画 START */}
      <div className={styles.mainImg}>
        <span
          className={classNames(
            'video-box is-loop',
            showStartVideo ? 'g-animation-rocket-show ' : ''
          )}
        >
          <span className="video-inner">
            <video src="/video/spaceship-loop.mp4" muted autoPlay loop></video>
          </span>
        </span>
        <span
          className={classNames(
            'video-box is-start',
            showStartVideo ? 'g-animation-rocket-hide ' : ''
          )}
        >
          <span className="video-inner">
            <video
              src={showStartVideo ? '/video/spaceship-appearance.mp4' : ''}
              muted
              autoPlay
            ></video>
          </span>
        </span>
      </div>
      {/* 火箭动画 END */}
      <BoxFrame innerClassName={styles.creativityFramInnerBox}>
        <ItemTitle
          size="small"
          items={[{ text: getFormatMessage('platforms_engines_supports') }]}
          innerClassName={styles.supportTitle}
        />
        <div className={styles.supportWrapper}>
          <div className={styles.supperIconBox}>
            <ItemTitle
              align="start"
              size="mini"
              type="inline"
              items={[
                { text: getFormatMessage('platforms'), textColor: 'blue' },
                { text: getFormatMessage('supports') },
              ]}
            />
            <div className={styles.suppoutIconList}>
              <span className={styles.iconSupport}>
                <IconWord width={52} height={52} />
              </span>
              <span className={styles.iconSupport}>
                <IconWindows width={48} height={48} />
              </span>
              <span className={styles.iconSupport}>
                <IconiOS width={50} height={50} />
              </span>
              <span className={styles.iconSupport}>
                <IconAndroid width={58} height={50} />
              </span>
              <span className={styles.iconSupport}>
                <IconPlayStation width={61} />
              </span>
              <span className={styles.iconSupport}>
                <IconSteam width={52} height={52} />
              </span>
            </div>
          </div>
          <div className={styles.supperIconBox}>
            <ItemTitle
              align="start"
              size="mini"
              type="inline"
              items={[
                { text: getFormatMessage('engines'), textColor: 'orange' },
                { text: getFormatMessage('supports') },
              ]}
            />
            <div className={styles.suppoutIconList}>
              <span className={styles.iconSupport}>
                <IconUnity width={52} height={52} />
              </span>
              <span className={styles.iconSupport}>
                <Icon3D width={46} height={52} />
              </span>
              <span className={styles.iconSupport}>
                <IconNatiiveEngine width={50} height={50} />
              </span>
            </div>
          </div>
        </div>
      </BoxFrame>
    </div>
  );
};
