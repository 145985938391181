import { Theme } from '../model';
import { getString, setString } from './local-storage/interface-base';
const KEY = 'theme';

const getElement = () => document.querySelector('body');

const setBodyClassName = (
  body: HTMLBodyElement | null,
  theme: Theme | null
) => {
  if (body) {
    body.setAttribute('class', `theme-${theme || Theme.light}`);
  }
};

export const initTheme = () => {
  const body = getElement();
  const theme = getString<Theme>(KEY);
  if (theme) {
    setBodyClassName(body, theme);
    return;
  }
  setString(KEY, Theme.light);
  setBodyClassName(body, Theme.light);
};
