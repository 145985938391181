import {
  getLocationSearchParam,
} from '../../../lib';
import { getCookieToken } from './cookie';

const getTicketQuery = () => {
  return getLocationSearchParam('ticket');
};

export const getLoginToken = () => {
  return getTicketQuery() || getCookieToken();
};
