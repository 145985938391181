import { ENV_DEV } from '../../config';
import { getConfig } from '../../config/config';

export const getGCloudAPIEndpoint = () => {
  const { websiteURL } = getConfig();
  if (ENV_DEV) {
    return `http:${websiteURL}`;
  }
  return window.location.origin;
};
