import { Button, Space } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import styles from './style.module.scss';
import { ReactComponent as Logo } from '../../../assets/svg/logo.svg';
// import { ReactComponent as IconEarth } from '../../../assets/svg/earth.svg';
// import { ReactComponent as IconUser } from '../../../assets/svg/user.svg';
import classNames from 'classnames';
import getFormatMessage from '../../../locales/get-format-message';
import { ItemSiteSwitchComponent } from './item-stie-switch';
import { getConfig } from '../../../config';
import { getLocaleLanguage, LanguageType } from '../../../lib';
import { UserAccountComponent } from './user-account';
import { getLoginToken } from '../../lib';
import { jumpLogin } from '../../../lib/jump-website';

interface IProps {
  readonly expand?: boolean;
  readonly scrollPosition?: 'bottom' | 'top' | '';
}

export const HeaderComponent = (props: IProps) => {
  const consoleURL = getConfig()?.consoleURL;
  const userURL = getConfig()?.userURL;
  const documentConfig = getConfig()?.documentConfig;
  const ingameModule = documentConfig.modules.find(
    (module) => module.key === 'INGAME'
  );
  const currentStoreLanguage = getLocaleLanguage() || LanguageType.en_US;
  const ticket = getLoginToken();
  const handleDocumentationClick = () => {
    const pid = ingameModule?.pid[currentStoreLanguage];
    const url = consoleURL && pid ? `${consoleURL}/doc/detail/${pid}` : '';
    if (ticket) {
      if (url) {
        window.open(url, '_blank');
      }
    } else {
      jumpLogin(url);
    }
  };
  const handleDownloadCenterClick = () => {
    const url = consoleURL
      ? new URL(`https://${consoleURL}/download/download-center`)
      : undefined;
    if (ticket && consoleURL) {
      url?.searchParams.append('ingame_language', currentStoreLanguage);
      window.open(url?.toString(), '_blank');
    } else {
      jumpLogin(url?.toString());
    }
  };
  const handleToConsoleClick = () => {
    if (ticket && consoleURL) {
      const url = new URL(`https://${consoleURL}`);
      url.searchParams.append('ingame_language', currentStoreLanguage);
      window.open(url.toString(), '_blank');
    } else {
      jumpLogin();
    }
  };
  return (
    <div
      className={classNames(
        styles.headerNav,
        props.expand && styles.isScrollDown,
        props.scrollPosition !== 'top' && props.expand && styles.hasShadow
      )}
    >
      <div className={styles.navLogoItem}>
        <a href="/">
          <Logo width={81} height={23} />
        </a>
      </div>
      <div className={styles.mainContentSapce}></div>
      <div className={styles.navMainItem}>
        <div className={styles.navMoreIcon}>
          <MenuOutlined />
        </div>
        <ul className={styles.navMainListBox}>
          <li>
            <a href="/" className={location.pathname === '/' ? 'active' : ''}>
              {getFormatMessage('products_home')}
            </a>
          </li>
          <li>
            <a
              href="/get-started"
              className={location.pathname === '/get-started' ? 'active' : ''}
            >
              {getFormatMessage('get_started_uppercase')}
            </a>
          </li>
          <li>
            <a
              onClick={handleDocumentationClick}
              className={location.pathname === '/documentation' ? 'active' : ''}
            >
              {getFormatMessage('documentation')}
            </a>
          </li>
          <li>
            <a
              onClick={handleDownloadCenterClick}
              className={
                location.pathname === '/download-center' ? 'active' : ''
              }
            >
              {getFormatMessage('download_center')}
            </a>
          </li>
          {/* <li>
            <a href="">{getFormatMessage('about_us')}</a>
          </li> */}
        </ul>
      </div>
      <div className={styles.navSideItem}>
        <Space size={32}>
          <ul className={styles.navMainListRightBox}>
            <ItemSiteSwitchComponent />
          </ul>
          <UserAccountComponent
            consoleURL={consoleURL}
            userURL={userURL}
            handleDownloadCenterClick={handleDownloadCenterClick}
          />
          {/* <div className={styles.navUser} onClick={onHandleLogin}>
            <IconUser width={16} height={16} />
          </div> */}
          <Button type="primary" onClick={handleToConsoleClick}>
            {getFormatMessage('dev_portal')}
          </Button>
        </Space>
      </div>
    </div>
  );
};
