import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { combinedReducers } from './reducers';

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  combinedReducers,
  composeEnhancers(applyMiddleware(thunk))
);
