export const ROUTER_PROJECT_CENTER_NAME = 'project-center'; // 项目中心
export const ROUTER_PROJECT_LIST_NAME = 'project-list'; // 项目列表
export const ROUTER_MARKET_NAME = 'market';

export const getDefaultRouterProjectCenter = (pid: string) => {
  return `/${ROUTER_PROJECT_CENTER_NAME}/${pid}/measure/dashboard`;
};

export const getRouterTapdSchedulePath = (pid: string) => {
  return `/${ROUTER_PROJECT_CENTER_NAME}/${pid}/tapd/schedule`;
};

export const getRouterDevopsCreatePipeLinePath = (pid: string) => {
  return `/${ROUTER_PROJECT_CENTER_NAME}/${pid}/settings/measure-setting/pipeline-setting`;
};
