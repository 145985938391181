import { Space } from 'antd';
import styles from './style.module.scss';
import leadStudiosLogo1 from '../../../assets/images/c-lead-studios-icon-01.png';
import leadStudiosLogo2 from '../../../assets/images/c-lead-studios-icon-02.png';
import leadStudiosLogo3 from '../../../assets/images/c-lead-studios-icon-03.png';
import leadStudiosLogo4 from '../../../assets/images/c-lead-studios-icon-04.png';
import leadStudiosLogo5 from '../../../assets/images/c-lead-studios-icon-05.png';
import getFormatMessage from '../../../locales/get-format-message';

export const LeadStudios = () => {
  return (
    <Space direction="vertical" wrap size={32} className={styles.leadStudios}>
      <p className="lead-studios-text">
        {getFormatMessage('trusted_by_studios')}
      </p>
      <div className={styles.leadStudiosLogo}>
        <span className="logo-item">
          <img src={leadStudiosLogo1} width={127} />
        </span>
        <span className="logo-item">
          <img src={leadStudiosLogo2} width={198} />
        </span>
        <span className="logo-item">
          <img src={leadStudiosLogo3} width={64} />
        </span>
        <span className="logo-item">
          <img src={leadStudiosLogo4} width={169} />
        </span>
        <span className="logo-item">
          <img src={leadStudiosLogo5} width={96} />
        </span>
      </div>
    </Space>
  );
};
