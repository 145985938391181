import React, { Fragment, useState } from 'react';
import { Input } from 'antd';
import { BoxFrame } from '../../common/box-frame';
import DecorateNail from '../../../assets/images/c-try-free-box-nail.png';
import DecorateRock from '../../../assets/images/c-try-free-box-rock.png';
import styles from './style.module.scss';
import classNames from 'classnames';
import getFormatMessage from '../../../locales/get-format-message';
import { getConfig } from '../../../config';
import { getLocaleLanguage, LanguageType } from '../../../lib';

interface IDEtailItems {
  readonly text?: string;
}
interface IProps {
  readonly defatultText?: string;
  readonly title?: string;
  readonly detailItems?: IDEtailItems[];
  readonly className?: any;
  readonly defatultTextSize?: 'small';
  readonly extraDetailSize?: 'small';
}
const { Search } = Input;
export const TryFreeBox = (props: IProps) => {
  const [email, setEmail] = useState<string>('');
  const userURL = getConfig()?.userURL;
  const handleSearch = () => {
    const currentStoreLanguage = getLocaleLanguage() || LanguageType.en_US;
    const params = new URLSearchParams();
    if (email) {
      params.append('email', email);
    }
    if (currentStoreLanguage) {
      params.append('ingame_language', currentStoreLanguage);
    }
    const queryString = params.toString();
    const url = `${userURL}/contact${queryString ? `?${queryString}` : ''}`;
    window.location.href = url;
  };
  return (
    <>
      <BoxFrame className={props.className}>
        <div className={styles.tryFreeBoxContent}>
          <span className={classNames(styles.decorate, styles.isNail)}>
            <img src={DecorateNail} />
          </span>
          <span className={classNames(styles.decorate, styles.isRock)}>
            <img src={DecorateRock} />
          </span>
          <div className={styles.contentTextBox}>
            <p className={classNames('text-item is-default')}>
              {props.defatultText}
            </p>
            <p
              className={classNames(
                'text-item is-title',
                props.defatultTextSize === 'small' && 'is-small'
              )}
            >
              {props.title}
            </p>
            <p
              className={classNames(
                'text-item is-extra-detail',
                props.extraDetailSize === 'small' && 'is-small'
              )}
            >
              {props.detailItems?.map((item, index) => (
                <Fragment key={index}>
                  {item.text}
                  <br />
                </Fragment>
              ))}
            </p>
          </div>
          <Search
            placeholder={getFormatMessage('email_address')}
            // allowClear
            enterButton={getFormatMessage('get_started')}
            size="large"
            className="c-search-type-main is-orange"
            onSearch={handleSearch}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </BoxFrame>
    </>
  );
};
