import { Moment } from 'moment';
import { IString } from '../model';

export function parseQueryString<T>(url: string): T {
  const result: { [x: string]: string } = {};
  if (typeof url !== 'string') {
    return result as unknown as T;
  }
  const str = url.split('?');
  const items = str && str.length > 1 ? str[1].split('&') : [];
  let arr = [];

  items.forEach((item) => {
    arr = item.split('=');
    result[decodeURIComponent(arr[0])] = decodeURIComponent(arr[1]);
  });

  return result as unknown as T;
}

export function urlWithQueryString(
  url: string,
  params: { [key: string]: string | number },
  traditional?: boolean
): string {
  const qs = Object.keys(params)
    .map((key) => {
      if (traditional) {
        const arrayItems = params[key];
        if (Array.isArray(arrayItems)) {
          return arrayItems
            .map((item) => `${key}${encodeURIComponent('[]')}=${item}`)
            .join('&');
        }
      }
      return `${key}=${encodeURIComponent(params[key])}`;
    })
    .join('&');

  if (!qs.length) {
    return url;
  }

  if (url.indexOf('?') === -1) {
    return `${url}?${qs}`;
  }
  return `${url}&${qs}`;
}

export function replaceStr(str: string, params: any, suffix?: string) {
  let newStr = '';
  const arrKey = str.match(/[^{{]+(?=}})/g);
  if (arrKey) {
    arrKey.forEach((item) => {
      if (params[item] !== undefined) {
        newStr = str.replace(`{{${item}}}`, params[item]);
      }
    });
  }
  if (suffix) {
    newStr += suffix;
  }
  return newStr;
}
export function urlWithDateQueryString(
  url: string,
  params: { [key: string]: string | Moment | undefined }
): string {
  const qs = Object.keys(params)
    .map((key) => {
      let val = params[key];
      if (typeof val === 'string') {
        return `${key}=${encodeURIComponent(val)}`;
      }
      if (val) {
        val = val
          .format('YYYY-MM-DDTHH:mm:ssZ')
          .replace('+', '%2B')
          .replace(/8:00$/, '0000');
        return `${key}=${val}`;
      }
      return '';
    })
    .filter((item) => !!item)
    .join('&');

  if (!qs.length) {
    return url;
  }

  if (url.indexOf('?') === -1) {
    return `${url}?${qs}`;
  }
  return `${url}&${qs}`;
}

export const getGameParamsByUrl = () => {
  const paths = location.pathname.split('/');
  const gameIdIndex = paths.findIndex((v) => +v);
  const gameId = paths[gameIdIndex] || '';
  const envId = paths[gameIdIndex + 1] || '';
  return {
    paths: paths.filter((v) => !+v && !['null', 'undefined'].includes(v)),
    gameId,
    envId,
  };
};

export const getLocationSearchParam = (key: string, search?: string) => {
  const params = new URLSearchParams(search || location.search);
  return params.get(key);
};

/**
 * /g/{gameId}/1/gaas/playertable  替换变量
 * 可用变量
 * gameid: 游戏id,
 * envId: 环境id,
 * guid: 环境guid
 * ticket: ticket
 */
export const parseVariablePath = (path: string, variableMap: IString<any>) => {
  const reg = /\{([a-zA-Z0-9]*)\}/g;
  const newPath = path.replace(reg, (_, variableName) => {
    return variableMap[variableName];
  });
  return newPath;
};
