import { getConfig } from '../config';
import { getString, setString } from './local-storage/interface-base';
import { reload } from './webview/webview';

export enum LanguageType {
  'zh_CN' = 'zh_CN',
  'en_US' = 'en_US',
}

const KEY = 'ingame_language';

export function setLocaleLanguage(value: LanguageType) {
  setString(KEY, value);
}

export function getLocaleLanguage(): LanguageType | null {
  return getString(KEY);
}

Object.assign(window, {
  __setLanguage: (value: LanguageType) => {
    setLocaleLanguage(value || LanguageType.en_US);
    reload();
  },
});

export const languageSwitch = () => {
  if (getLocaleLanguage() === 'en_US') {
    window.__setLanguage('zh_CN');
  } else {
    window.__setLanguage('en_US');
  }
};
