import { Button, Carousel, Space } from 'antd';
import { BoxFrame } from '../../common/box-frame';
import { LeadStudios } from '../lead-studios';
import { Fragment, useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './style.module.scss';
import getFormatMessage from '../../../locales/get-format-message';
import bannerNew from '../../../assets/images/banner-new.png';
import bannerSpecial from '../../../assets/images/banner-special.png';
import bannerThird from '../../../assets/images/banner-third.png';
import bannerThirdCN from '../../../assets/images/banner-third-cn.png';
import starIcon from '../../../assets/images/star-icon.png';
import lineIcon from '../../../assets/images/line-icon.png';
import { getLocaleLanguage, LanguageType } from '../../../lib/language';
import { DeployArea } from '../../../model/config';
import { getConfig } from '../../../config/config';
interface IBUttonList {
  readonly buttonText?: string;
  readonly buttonHref?: string;
  readonly buttonType?: 'primary';
  readonly buttonSize?: 'small' | 'large';
}
interface ITItleItemsProps {
  readonly text?: string;
  readonly textColor?: 'primary';
  readonly textSize?: 'small';
  readonly starIcon?: string;
  readonly lineIcon?: string;
}
interface IEXtraTextListProps {
  readonly text?: string;
  readonly textBlue?: string;
  readonly textTwo?: string;
  readonly textBlack?: string;
}
interface IImageBgProps {
  readonly imageUrl?: string;
  readonly imageStyleName?: string;
}
interface IProps {
  readonly imageBg?: IImageBgProps[];
  readonly titleItems?: ITItleItemsProps[];
  readonly extraTextList?: IEXtraTextListProps[];
  readonly buttonList?: IBUttonList[];
  readonly algn?: 'center';
  // readonly hasLeadStudios?: boolean;
}
const TitleItem = (props: IProps) => {
  return (
    <h2>
      {props.titleItems?.map((item, index) => (
        <span
          className={classNames(
            'banner-text',
            item.textColor === 'primary' && 'is-color-primary',
            item.textSize === 'small' && 'is-small'
          )}
          key={index}
        >
          {item.text}
          {item.starIcon && <img src={item.starIcon} className='star-icon'/>}
          {item.lineIcon && <img src={item.lineIcon} className='line-icon'/>}
        </span>
      ))}
    </h2>
  );
};
const ExtraTextList = (props: IProps) => {
  return (
    <>
      {props.extraTextList?.map((item, index) => (
        <p className='extra-text'>
        <Fragment key={index}>
          {item.text}
          {item.textBlue && <span className="blue-text">{item.textBlue}</span>} 
          {item.textTwo}
          {item.textBlack && <span className="black-text">{item.textBlack}</span>} 
          {props.extraTextList && props.extraTextList.length > 1 && <br />}
        </Fragment>
        </p>
      ))}
    </>
  );
};
const ButtonList = (props: IProps) => {
  return (
    <>
      {props.buttonList && (
        <Space>
          {props.buttonList?.map((item, index) => (
            <Button
            className={classNames(
              item.buttonType === 'primary' && 'is-btn-primary'
            )}
              key={index}
              type={item.buttonType}
              size={item.buttonSize}
              href={item.buttonHref}
            >
              {item.buttonText}
            </Button>
          ))}
        </Space>
      )}
    </>
  );
};
const ImageBg = (props: IProps) => {
  return (
    <>
      {props.imageBg?.map((item, index) => (
        <img
          className={classNames(
            item.imageStyleName
          )}
          src={item.imageUrl}
          key={index}
        />
      ))}
    </>
  );
};
export const BannerBox = (props: IProps) => {
  const currentLanguage = getLocaleLanguage() || LanguageType.en_US;
  const deployArea: DeployArea = getConfig()?.deployArea;
  const isWoa = deployArea === DeployArea.woa;
  const bannerThirdPath = currentLanguage === LanguageType.en_US ? bannerThird : bannerThirdCN;

  // 添加slick-dots父元素
  useEffect(() => {
    const slickDots = document.querySelector('.slick-dots');
    if (slickDots?.parentNode) {
      const parentElement = document.createElement('div');
      parentElement.classList.add('slick-dots-parent');
      slickDots.parentNode.insertBefore(parentElement, slickDots);
      parentElement.appendChild(slickDots);
    }
  }, []);
  const slides = [
    {
      titleItems: [
        {
          text: getFormatMessage('in_game_special'),
        },
        {
          text: getFormatMessage('special_campaign'),
          textColor: 'primary',
          starIcon,
          lineIcon
        },
      ],
      imageBg:[{
        imageUrl: bannerSpecial,
        imageStyleName:'banner-bg2'
      }],
      extraTextList: [{ text: getFormatMessage('in_game_services_focus_special')}
    ],
      buttonList: [
        {
          buttonType: 'primary',
        },
      ]
    },
    {
      titleItems: [
        {
          text: getFormatMessage('the_ingame'),
        },
        {
          text: getFormatMessage('platform_is_now_live'),
          textColor: 'primary',
        },
      ],
      imageBg:[{
        imageUrl: bannerNew,
        imageStyleName:'banner-bg1'
      }],
      extraTextList: [{ 
        text: getFormatMessage('in_game_services_focus_new'),
        textBlue:getFormatMessage('in_game_services_focus_new2'),
        textTwo:getFormatMessage('in_game_services_focus_new3')}
      ],
      buttonList: [
        {
          buttonType: 'primary',
        },
      ]
    },
    {
      titleItems: [
        {
          text: getFormatMessage('let_build_an'),
        },
        {
          text: getFormatMessage('excellent_game_from_here'),
          textColor: 'primary',
          textSize:'small'
        },
      ],
      imageBg:[{
        imageUrl: bannerThirdPath,
        imageStyleName:'banner-bg3'
      }],
      extraTextList: [{ textBlack: getFormatMessage('in_game_services_focus')}],
      buttonList: [
        {
          buttonText: getFormatMessage('get_started_for_free'),
          buttonSize: 'large',
          buttonType: 'primary',
          buttonHref: '/get-started',
        },
      ]
    },
  ];
  const slidesList = isWoa ? slides : slides.slice(1);
  return (
    <div className='carousel-container'>
      {/* Banner */}
      <Carousel autoplay autoplaySpeed={3000} fade={true} pauseOnHover={false}>
      {slidesList.map((slide, index) => (
        <div className={styles.banner}>
        <div className="banner-container" key={index}>
          <BoxFrame
            className={classNames(
              props.algn === 'center' && styles.isCenter,
              styles.active
            )}
            innerClassName="banner-inner"
          >
            <Space direction="vertical" className={styles.bannerInfoBox}>
              <div className={classNames(currentLanguage === LanguageType.zh_CN && 'bg-content-cn')}>
                <ImageBg imageBg={slide.imageBg as IImageBgProps[]} />
                <div className={classNames(currentLanguage === LanguageType.zh_CN && 'text-content-cn')}>
                  <TitleItem titleItems={slide.titleItems as ITItleItemsProps[]} />
                  <ExtraTextList extraTextList={slide.extraTextList} />
                </div>
              </div>
              <ButtonList buttonList={slide.buttonList as IBUttonList[]} />
            </Space>
          </BoxFrame>
        </div>
        </div>
      ))}
      </Carousel>
        <div className='lead-container'>
        <LeadStudios />
        </div>
  </div>
  );
};
