import ZHCN from './zh-cn';
import ENUS from './en-us';
import { getLocaleLanguage, LanguageType } from '../lib';

export const getLanguage = getLocaleLanguage();

const loadLanguageText = (language: LanguageType): Record<string, string> => {
  if (language === LanguageType.zh_CN) {
    return ZHCN;
  }
  // default load en us language
  return ENUS;
};

function getStr(str: string, params: any) {
  const arrKey = str.match(/[^{{]+(?=}})/g);
  let newStr = str;
  if (arrKey) {
    arrKey.forEach((item) => {
      if (params[item] !== undefined) {
        newStr = newStr.replace(`{{${item}}}`, params[item]);
      }
    });
  }
  return newStr;
}

/*
  value: 待翻译值
  ignoreCase: 是否忽略大小写，默认true 
*/
interface IOptions {
  ignoreCase?: boolean;
  params?: any;
}
function getFormatMessage(value: string, options: IOptions = {}) {
  const { ignoreCase = true, params } = options;
  const translateText = loadLanguageText(getLanguage || LanguageType.en_US);

  let key = value;
  if (ignoreCase) {
    key = key.toLowerCase();
  }
  let res = translateText[key] || translateText[value];
  if (res === undefined) {
    res = value;
  }
  if (params) {
    res = getStr(res, params);
  }

  return res;
}

export default getFormatMessage;
