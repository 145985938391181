export default {
    let_build_an:'Let\'s build an',
    excellent_game_from_here:'excellent game from here',
    in_game_services_focus:'InGame services make you focus on innovation',
    get_started_for_free:'Get started for free now',
    // 全新
    the_ingame:'The InGame',
    platform_is_now_live:'Platform is now live!',
    in_game_services_focus_new:'Specifically designed for global game distribution, Never Worry About Your Game Backend Again!',
    in_game_services_focus_new2:'',
    in_game_services_focus_new3:'',
    learn_more_btn:'Learn More',
    // 特别企划
    in_game_special:'InGame',
    special_campaign:'Special Campaign',
    in_game_services_focus_special:'Join Hands with the Bamboo Shoot Program to Support the Growth of Potential Projects.',
    in_game:'',
    is_a_adaptable:'InGame is a highly adaptable',
    gaming_backend:'gaming backend',
    crafting_dynamic_desc:'Crafting a dynamic multiplayer online gaming universe',
    harness_power_our_core_desc:'Harness the power of our core backend capabilities to build a thriving multiplayer online gaming ecosystem.',
    explore_multiplayer:'Explore Multiplayer',
    player_centric:'Player-Centric',
    supports_login_matchmaking_desc1:'Supports login and matchmaking across all platforms.',
    supports_login_matchmaking_desc2:'Low latency, quick start for each round.',
    supports_login_matchmaking_desc3:'Enriches the online gaming experience for players.',
    flexible_matchmaking:'Flexible Matchmaking',
    supports_custom_matchmaking_desc1:'Supports custom matchmaking logic.',
    supports_custom_matchmaking_desc2:'Different matchmaking pools based on player information for prioritized matchmaking rules.',
    highly_scalable:'Highly Scalable',
    expand_game_backend_desc1:'Expand your game\'s backend interaction scenarios with our plug-and-play microservices.',
    expand_game_backend_desc2: '',
    interactive_social:'Interactive Social',
    enabling_players_from_desc:'Enabling players from different platforms to interact within the game',
    create_game_social_layer_desc:'Create your game\'s social layer, enabling players from different platforms to interact within the game.',
    cross_platform:'Cross-Platform',
    supports_cross_platform_desc1:'Supports cross-platform friend features,',
    supports_cross_platform_desc2: 'unrestricted by platform or device,',
    supports_cross_platform_desc3: ' fostering deep social relationships and enhancing game stickiness.',
    real_time_interaction:'Real-Time Interaction',
    high_quality_text_and_voice_desc1:'High-quality text and voice chat for real-time interaction,',
    high_quality_text_and_voice_desc2: ' supporting various game modes to improve communication efficiency and collaborative experience.',
    high_quality_text_and_voice_desc3: '',
    competitive_ranking:'Competitive Ranking ',
    scalable_distributed_desc1:'Scalable distributed game ranking, easily accommodating millions of players on the same leaderboard.',
    scalable_distributed_desc2:'Supports dynamic creation of leaderboards for hundreds of thousands, quickly implementing regional rankings.',
    content_update:'Content Update',
    all_platform_universal_desc:'All-Platform Universal Content Distribution Technology for games\' version updates',
    develop_a_universal_desc:'Develop a universal content distribution technology for all platforms, meeting the version content update needs of long-term game operation.',
    explore_content:'Explore Content',
    leading_infrastructure:'Leading Infrastructure',
    server_nods_cover_desc1:'Server nodes cover the globe,',
    server_nods_cover_desc2:'enabling worldwide distribution with a single release.',
    game_appropriate:'Game-Appropriate',
    provides_unified_game_version_desc1:'Provides a unified game version management system across multiple platforms,supporting program updates, resource updates, and play-while-downloading.',
    provides_unified_game_version_desc2:'',
    rapid_dynamic_deployment:'Rapid Dynamic Deployment',
    cloud_based_simple_storage_desc1:'Cloud-based simple storage, complete application changes and deployment within minutes, providing comprehensive, precise, and dynamic configuration delivery.',
    cloud_based_simple_storage_desc2:'',
    economic_system:'Economic System',
    build_long_term_operational_desc:'Economic System: Building a long-term operational business ecosystem',
    create_game_social_different_desc:'Build a long-term operational business ecosystem, empowering your business with mature online game operation models.',
    explore_economy:'Explore Economy',
    comprehensive_economic_ecosystem:'Comprehensive Economic Ecosystem',
    a_complete_economic_system_desc1:'A complete economic system including payment, shopping, in-game assets, and inventory, enriching the player\'s gaming experience.',
    a_complete_economic_system_desc2:'',
    flexible_asset_management:'Flexible Asset Management',
    in_game_item_and_virtual_desc1:'In-game item and virtual currency management, along with storage solutions like warehouse and backpack services, providing a safer, more efficient, and convenient in-game asset management.',
    in_game_item_and_virtual_desc2:'',
    in_game_item_and_virtual_desc3:'',
    data_analysis:'Data Analysis',
    creating_mature_data:'Creating a mature data analysis system for your game',
    build_data_analysis_desc:'Build a data analysis interactive ecosystem, helping users monitor performance, understand players, and provide a more attractive experience.',
    explore_analytics:'Explore Analytics',
    mature_analytics_system:'Mature Analytics System',
    easily_understand_your_name_desc1:'Easily understand your game\'s data performance, fully grasp game operation data, intelligently analyze and optimize game performance, aiding business decisions.',
    easily_understand_your_name_desc2:'',
    efficient_self_service_desc:'Efficient Self-Service Analysis',
    convenient_and_efficient_data_desc1:'Convenient and efficient data reporting, enabling developers to conduct self-service exploratory analysis in custom scenarios.',
    convenient_and_efficient_data_desc2:'',
    intelligent_performance_monitoring:'Intelligent Performance Monitoring',
    fine_grained_event_desc1:'Fine-grained event and process monitoring, real-time alerts, self-healing faults, helping developers quickly locate and resolve issues, ensuring stable and secure global online gaming.',
    fine_grained_event_desc2:'',
    ready_to_build_game:'Ready to build your game?',
    get_started_now:'Get started now for free!',
    let_innovate_experiences_desc:' Let\'s innovate in-game experiences together with our full-service solutions.',
    ingame_upgrade_notification: 'InGame Upgrade Notification',
}