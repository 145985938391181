import { LanguageType } from '../lib/language';
import { AppEnvType, DeployArea, IAppConfig } from '../model';

const config: IAppConfig = {
  env: AppEnvType.Development,
  appID: 'intl-gcloud',
  userID: '-',
  consoleURL: '',
  userURL: '',
  websiteURL: '',
  deployArea: DeployArea.singapore,
  copyRight: {
    copyRightText: '',
    protocolLinks: {}
  },
  languageConfig: {
    currentLanguage: 0,
    languageList: []
  },
  cookieName: 'DEV_INTL_GCLOUD_TICKET',
  aegisConfig: {
    enable: false,
    id: '',
    hostUrl: '',
  },
  documentConfig: {
    modules: []
  },
  legalTerms: {
    enabledCookieTips: false,
    dataTransferProtocol: {
      enabled: false,
      url: '',
      label: '',
    },
    privacyPolicyProtocol: {
      enabled: false,
      url: '',
      label: '',
    },
    termsOfServiceProtocol: {
      enabled: false,
      url: '',
      label: '',
    },
  },
};
export default config;
