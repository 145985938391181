export class Uuid {
  private create() {
    return `${this.getRandom()}`;
  }

  private getRandom() {
    const str = 'xxxyxxxxxxx_';
    const times = Date.now();
    const strRule = times.toString(16);
    return (
      str.replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }) + strRule
    ).toUpperCase();
  }

  public get uuid() {
    return this.create();
  }
}
