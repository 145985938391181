import React, { useEffect, useState } from 'react';
import { FooterComponent } from '../../../pages/components/footer';
import { HeaderComponent } from '../../../pages/components/header';
import { BackTopBox } from '../../../pages/components/back-top-box';
import styles from './style.module.scss';
import { GlobalNotification } from '../../../pages/components/global-notification';

interface IProps {
  readonly children: React.ReactNode;
}

export const LayoutDefault = (props: IProps) => {
  const [lastScrollTop, setLastScrollTop] = useState<number>(0);
  const [scrollDown, setScrollDown] = useState<boolean>(false);
  const [scrolling, setScrolling] = useState<boolean>(false);
  const [scrollPosition, setScrollPosition] = useState<'bottom' | 'top' | ''>(
    'top'
  );

  useEffect(() => {
    let scrollTimer: NodeJS.Timeout;
    const handleScroll = () => {
      const currentScrollTop =
        window.scrollY || document.documentElement.scrollTop;
      const { scrollHeight } = document.documentElement;
      const { clientHeight } = document.documentElement;
      // 判断滚动条是否有在滚动
      setScrolling(true);
      clearTimeout(scrollTimer);
      scrollTimer = setTimeout(() => {
        setScrolling(false);
      }, 150); // 150ms 内没有再次触发 scroll 事件，则认为停止滚动

      if (currentScrollTop > lastScrollTop) {
        // 向下滚动
        setScrollDown(true);
      } else {
        // 向上滚动
        setScrollDown(false);
      }
      setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);

      // 距底部距离
      const bottomDistance = scrollHeight - currentScrollTop - clientHeight;
      bottomDistance < 88 ? setScrollPosition('bottom') : setScrollPosition('');
      currentScrollTop < 88 && setScrollPosition('top');
    };
    // 判断滚动方向
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollTop]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <div className={styles.layoutDefaultBox}>
      <GlobalNotification />
      <HeaderComponent expand={!scrollDown} scrollPosition={scrollPosition} />
      <div className="layout-content">{props.children}</div>
      <FooterComponent />
      <BackTopBox
        onclick={scrollToTop}
        scrolling={scrolling}
        scrollPosition={scrollPosition}
      />
    </div>
  );
};
