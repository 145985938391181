import { useHistory } from 'react-router-dom';
import getFormatMessage from '../../locales/get-format-message';
import './style.scss';

export const NotFound = () => {
  const history = useHistory();
  const toHome = () => {
    history.push('/');
  };

  return (
    <div className="not-found-page">
      <div className="box">
        <div className="not-found-img"></div>
        <div className="style-center">404</div>
        <div className="tips">
          <span>{getFormatMessage('sorry_page_not_exist')}</span>
        </div>
        <div className="gc-btn gc-btn-primary" onClick={toHome}>
        {getFormatMessage('back')}
        </div>
      </div>
    </div>
  );
};
