export default {
    begin_your_development_adventure:'Begin your development adventure by downloading the SDK,',
    setting_up_services:'setting up services through the Developer Portal,',
    and_diving_into_samples:'and diving into our samples and documentation for a seamless start.',
    embark_on_your_in_game_journey:'Embark on Your InGame Journey',
    just_3_steps:'Just 3 Steps Away',
    step_1:'Step 01',
    get_started_with_account:'Get Started with Your Account and Service Configuration',
    start_by_leaving_desc:'Start by leaving your contact information on our "Contact Us" page. Our team will reach out to you within 24 hours to provide you with an InGame platform account. Once you have your account, you can set up your game and configure your game services, optimizing your use of our platform.',
    get_your_account:'Get Your Account',
    step_2:'Step 02',
    download_sdk_link:'Download SDK and Link Your Game',
    once_you_logged_in_desc:'Once you\'re logged in, head over to our Download Center to download the latest InGame SDK. We\'ve got you covered whether you\'re on PC, Android, iOS, PlayStation, or Xbox. After you\'ve got the SDK installed, you can seamlessly integrate it with your game and kickstart your journey with our services.',
    log_in_to_desc:'Log in to Developer Portal',
    step_3:'Step 03',
    dive_into_documentation:'Dive into the Documentation',
    we_have_put_together_comprehensive_desc:'We\'ve put together a comprehensive suite of technical documentation for you. This includes in-depth technical rundowns of each service, API references, and guides for the sample codes included in the SDK. By exploring these resources, you\'ll gain a thorough understanding of our products and services.',
    explore_documentation:'Explore Documentation',
    fueling_journey_to_success:'Fueling Your Journey to Success',
    we_believe_power_connected_desc:'We believe in the power of a connected, inclusive gaming universe, where every developer, big or small, ',
    has_opportunity_to_shine_desc:'has the opportunity to shine. By offering our cutting-edge online services to all, ',
    we_are_handing_unlock_desc:'we\'re handing you the keys to unlock limitless possibilities.',

}