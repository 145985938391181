import { getAppConfigApi } from '../api';
import { AppEnvType, IAppConfig } from '../model';
import extend from 'extend2';
import { md5, strToJson } from './utils';

const DEV_ENV_TOKEN = '5a4c2ef082ad2ea37b14c9de6df20bc6'; // it's safe

const getLocalConfig = () => {
  let runTimeConfig = {};
  try {
    // eslint-disable-next-line
    runTimeConfig = require(`../env.${process.env.NODE_ENV}.json`);
  } catch (error) {}
  /*eslint-disable*/
  const defaultConfig = require(`../config/config.default.ts`).default;
  return {
    runTimeConfig,
    defaultConfig,
  };
};

const verifySign = (sign: string, remoteHostname: string) => {
  return sign === md5(remoteHostname);
};

const getSign = (env?: AppEnvType) => {
  return env === AppEnvType.Development
    ? DEV_ENV_TOKEN
    : md5(location.hostname);
};

export const getRemoteAppConfig = async (env?: AppEnvType) => {
  const sign = getSign(env);
  const appConfig = await getAppConfigApi(sign);
  if (!appConfig) {
    return null;
  }
  if (!verifySign(sign, appConfig?.Domain)) {
    return null;
  }

  const remoteConfig = strToJson<IAppConfig>(appConfig.JsonConfig);
  const local = getLocalConfig();
  const config: IAppConfig = extend(
    true,
    {},
    local.defaultConfig,
    local.runTimeConfig,
    remoteConfig
  );
  if (!config.consoleURL || !config.userURL) {
    return null;
  }
  return config;
};
