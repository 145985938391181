/**
 * 工具函数库
 */

import SparkMD5 from 'spark-md5';

// 获取域名
export function getDomain() {
  const { hostname } = location;
  // IP 直接返回hostname
  if (
    /((25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))\.){3}(25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))/.test(
      hostname
    )
  ) {
    return hostname;
  }
  const hostArr = hostname.split('.');
  return `.${hostArr.slice(-2).join('.')}`;
}

// md5加密
export const md5 = (value: string) => {
  if (value) {
    return SparkMD5.hash(value);
  }
  return '';
};

// 时间格式处理为 YYYY-MM-DD HH:mm:ss
export const getFormatDateTime = (date: Date) => {
  if (!date) return '';
  const strDate = date.toString();
  if (strDate.indexOf('0000-00-00') !== -1) {
    return '--';
  }
  // 格式需要转化
  const formattedDate = strDate.replace('T', ' ').replace('.000Z', '');
  return formattedDate;
};

export const strToJson = <T>(str: string): T | null => {
  try {
    return JSON.parse(str);
  } catch (err) {
    return null;
  }
};
