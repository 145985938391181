import { Dispatch, useEffect, useState } from 'react';
import styles from './style.module.scss';
import { useDispatch } from 'react-redux';
import { ReactComponent as IconUser } from '../../../assets/svg/user.svg';
import { IAPIUserInfo } from '../../../model';
import { CommonAction } from '../../../store/modules/common';
import { getLoginToken } from '../../lib';
import { getLogout, getUserInfo } from '../../../api/user';
import getFormatMessage from '../../../locales/get-format-message';
import { Dropdown } from 'antd';
import { DropDownBox } from '../../../components/dropdown-box';
import accountIcon from '../../../assets/images/account-icon.png';
import { jumpConsole, jumpLogin, jumpLoginToAccount } from '../../../lib/jump-website';

interface IProps {
    readonly consoleURL: string;
    readonly userURL?: string;
    readonly handleDownloadCenterClick: () => void;
}
export const UserAccountComponent = (props: IProps) => {
    const userDispatch = useDispatch<Dispatch<CommonAction>>();
    const [userInfo, setUserInfo] = useState<IAPIUserInfo>();
    const { consoleURL } = props;
    // console.log('传递的consoleURL,userURL',consoleURL,userURL);
    useEffect(() => {
        getUserInfoContent();
    }, []);
    const onHandleLogin = () => {
        const ticket = getLoginToken();
        if (ticket) {
            jumpConsole(ticket);
        } else {
          // 先去用户中心登录带上?show-account-center=1参数
            jumpLoginToAccount();
        }
    }
    const onLogout = async () => {
      const res = await getLogout();
      if (res) {
        jumpLogin();
      }
      return !!res;
    };
    const getUserInfoContent = async () => {
      const ticket = getLoginToken();
      const res = await getUserInfo();
      // console.log('IAPIUserInfo,ticket',res,ticket);
        if (res && ticket) {
            userDispatch({
            type: 'userInfo',
            result: res,
            });
            setUserInfo(res);
        }
    };
    const userItems = [
        {
          key: '1',
          label: (
            <>
              <div className="account-text ellipsis">{userInfo?.UserName}</div>
              <div className="account-text2 ellipsis">
              {userInfo?.UserID}
                <span className="account-line"></span>
              </div>
            </>
          ),
          className: 'dropdown-item-user',
        },
        {
          key: '2',
          label: getFormatMessage('account_label'),
          onClick: () => {
            const url = `${consoleURL}?show-account-center=1`;
            window.location.href = url;
            // 用户中心
          },
        },
        {
          key: '4',
          label: getFormatMessage('download_center'),
          onClick: () => props.handleDownloadCenterClick(),
        },
        {
          key: '3',
          label: getFormatMessage('sign_out_label'),
          onClick: () => {
            // 退出登录
            onLogout();
          },
        },
      ];

    return (
        <>
          {userInfo ? (
            <Dropdown
              menu={{ items: userItems }}
              destroyPopupOnHide={true}
              overlayClassName={styles.lgoDropdownBottom}
            >
              <a className="action-account action-account-user">
                <DropDownBox
                  className="drop-down-box"
                  hasArrow={false}
                  fontSize={16}
                  title={<img src={accountIcon} />}
                />
              </a>
            </Dropdown>
          ) : (
            <div className={styles.navUser} onClick={onHandleLogin}>
              <IconUser width={16} height={16} />
            </div>
          )}
        </>
    );

};