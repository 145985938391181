import { LanguageType } from "../lib/language";

export enum AppEnvType {
  'Development' = 'development',
  'Test' = 'test',
  'PreRelease' = 'pre-release',
  'Production' = 'production',
}

export enum AppRegionType {
  'Singapore' = 'singapore',
  'China' = 'china',
}

export enum StepStatus {
  Progress = 'Progress',
  Waiting = 'Waiting',
  Success = 'Success',
  Fail = 'Fail',
  Finished = 'Finished',
}

export enum DeployArea {
  'cn' = 'cn',
  'singapore' = 'singapore',
  'woa' = 'woa',
}

export interface IAppConfig {
  readonly env: AppEnvType;
  readonly appID: string;
  readonly userID: string;

  // urls full url => location.origin : https://xxx.com
  readonly consoleURL: string;
  readonly userURL: string;
  readonly websiteURL: string;

  readonly copyRight: IAppCopyRightConfig;
  readonly gameConfig?: IAppGameConfig;
  readonly aegisConfig: IAppAegisConfig;
  // target: scalable for deployment regions, but currently not needed.
  readonly areaType?: AppRegionType;

  // 为了浏览器可以同时登陆多个区域app，区分cookie，只是在命名上区分，实际使用时候会把各自的cookie值取出 重命名key为INTL_GCLOUD_TICKET
  readonly cookieName: string;

  readonly legalTerms: IAppConfigLegalTerms;

  // 文档配置
  readonly documentConfig: IAppDocConfig;
  readonly deployArea: DeployArea;

  readonly languageConfig: IAppWebsiteSwitchConfig;
}

export enum Theme {
  'dark' = 'dark',
  'light' = 'light',
}

// full url => location.origin : https://xxx.com
export interface IAppCopyRightConfig {
  readonly copyRightText: string;
  readonly filingCaseNumber?: string;
  readonly filingLink?: string;
  readonly protocolLinks: {
    [key: string]: IAppLinkConfig[];
  };
}

export interface IAppGameConfig {
  readonly region: ReadonlyArray<[string, string]>; // [['East China(Shanghai)', '4'], [], []]
}

export interface IAppAegisConfig {
  readonly enable: boolean;
  readonly id: string;
  readonly hostUrl: string; // https://rumt-sg.com
  readonly performanceUrl?: string; // https://aegis.qq.com/speed/performance
  readonly reportApiSpeed?: boolean;
  readonly reportAssetSpeed?: boolean;
  readonly spa?: boolean;
  readonly onError?: boolean;
}

export interface IAppLinkConfig {
  readonly label: string;
  readonly url: string;
}

export interface IAppConfigLegalTermsProtocol {
  readonly enabled: boolean;
  readonly url: string;
  readonly label: string;
}

export interface IAppConfigLegalTerms {
  readonly enabledCookieTips: boolean;
  readonly dataTransferProtocol: IAppConfigLegalTermsProtocol;
  readonly privacyPolicyProtocol: IAppConfigLegalTermsProtocol;
  readonly termsOfServiceProtocol: IAppConfigLegalTermsProtocol;
}

export interface ILanguageItem {
  readonly name: string;
  readonly key: LanguageType;
  readonly link: string;
}

export interface ILanguageListConfig {
  readonly area: string;
  readonly items: ILanguageItem[];
}

export interface IAppDocPidConfigItem {
  readonly en_US: number;
  readonly zh_CN: number;
}
export interface IAppDocModulesConfigItem {
  // readonly [key: string]: number;
  readonly key: string;
  readonly name: string;
  readonly pid: IAppDocPidConfigItem;
}

export interface IAppDocConfig {
  // readonly consoleHelperLinks: IAppDocConsoleHelperLinksConfig;
  readonly modules: IAppDocModulesConfigItem[];
}

export interface IAppWebsiteSwitchConfig {
  readonly currentLanguage: number;
  readonly languageList: ILanguageListConfig[];
}
