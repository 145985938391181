import Aegis from 'aegis-web-sdk';
import { getConfig } from '../config';
import { AppEnvType, IAppAegisConfig } from '../model';
interface IAegisAction {
  readonly info: (...rest: any) => void;
  readonly error: (...rest: any) => void;
  readonly setConfig: (key: string, value: string) => void;
}

const reportAegis = (config: IAppAegisConfig, uid?: string) => {
  if (window.aegis) {
    return window.aegis;
  }
  const opt = Object.assign(
    {
      id: config.id, // 项目key  --控制台
      uin: uid || '', // 用户唯一 ID（可选）
      reportApiSpeed: true, // 接口测速
      reportAssetSpeed: true, // 静态资源测速
      spa: true, // spa 页面需要开启，页面切换的时候上报pv
      hostUrl: config.hostUrl,
      onError: true, // 错误日志
      performanceUrl: config.performanceUrl, // 页面性能日志上报
    },
    config
  );
  window.aegis = new Aegis(opt);
};

export const aegis: IAegisAction = {
  info: (...rest: any) => {
    if (window.aegis) {
      window.aegis.info(...rest);
    }
  },
  error: (...rest: any) => {
    if (window.aegis) {
      window.aegis.error(...rest);
    }
  },
  setConfig: (key: string, value: string) => {
    if (window.aegis) {
      window.aegis.setConfig(key, value);
    }
  },
};

export const initAegis = (uid?: string) => {
  const config = getConfig();
  if (config.env === AppEnvType.Production && config.aegisConfig?.enable) {
    reportAegis(config.aegisConfig, uid);
  }
};
