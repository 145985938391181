import { combineReducers } from 'redux';
import { commonReducer, ICommonState } from './modules/common';

export interface IRootState {
  common: ICommonState;
}

export const combinedReducers = combineReducers<IRootState | undefined>({
  common: commonReducer,
});
