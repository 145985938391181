import { LayoutDefault } from '../../router/templates/default';
import imgSrc1 from '../../assets/images/p-get-started-img-01.png';
import imgSrc2 from '../../assets/images/p-get-started-img-02.png';
import imgSrc3 from '../../assets/images/p-get-started-img-03.png';
import { IntroduceItem } from '../components/introduce-item';
import { TryFreeBox } from '../common/try-free-box';
import { BannerStartedBox } from '../components/banner-started-box';
import styles from './style.module.scss';
import getFormatMessage from '../../locales/get-format-message';
import { getLocaleLanguage, LanguageType } from '../../lib';
import { getConfig } from '../../config';
import { getLoginToken } from '../lib';
import { jumpLogin } from '../../lib/jump-website';

export const GetStarted = () => {
  const currentStoreLanguage = getLocaleLanguage() || LanguageType.en_US;
  const userURL = getConfig()?.userURL;
  const consoleURL = getConfig()?.consoleURL;
  const ticket = getLoginToken();
  const documentConfig = getConfig()?.documentConfig;
  const ingameModule = documentConfig.modules.find(module => module.key === 'INGAME');
  const handleToConsoleClick = () => {
    if (ticket && consoleURL) {
      const url = new URL(`https://${consoleURL}`);
      url.searchParams.append('ingame_language', currentStoreLanguage);
      window.open(url.toString(), '_blank');
    } else {
      jumpLogin();
    }
  };
  const handleDocumentationClick = () => {
    const pid = ingameModule?.pid[currentStoreLanguage];
    if (ticket) {
      if (consoleURL && pid) {
        const url = `${consoleURL}/doc/detail/${pid}`;
        window.open(url, '_blank');
      }
    } else {
      jumpLogin();
    }
  };
  return (
    <LayoutDefault>
      <>
        <BannerStartedBox
          algn="center"
          extraTextList={[
            {
              text: getFormatMessage('begin_your_development_adventure'),
            },
            {
              text: getFormatMessage('setting_up_services'),
            },
            {
              text: getFormatMessage('and_diving_into_samples'),
            },
          ]}
          titleItems={[
            {
              text: getFormatMessage('embark_on_your_in_game_journey'),
              textSize: 'small',
            },
            {
              text: getFormatMessage('just_3_steps'),
              textColor: 'primary',
            },
          ]}
        />
        <IntroduceItem
          tagName={getFormatMessage('step_1')}
          itemTitle={getFormatMessage('get_started_with_account')}
          itemTitleExtraInfo={getFormatMessage('start_by_leaving_desc')}
          itemButtonText={getFormatMessage('get_your_account')}
          itemButtonHref={`${userURL}/contact?ingame_language=${currentStoreLanguage}`}
          tagColor="orange"
          sideImgSrc={imgSrc1}
          imgWidth={373.32}
          imgHeight={305}
          className={styles.introduceItem1}
          even
        />
        <IntroduceItem
          tagName={getFormatMessage('step_2')}
          itemTitle={getFormatMessage('download_sdk_link')}
          itemTitleExtraInfo={getFormatMessage('once_you_logged_in_desc')}
          itemButtonText={getFormatMessage('log_in_to_desc')}
          itemButtonClick={handleToConsoleClick}
          tagColor="green"
          sideImgSrc={imgSrc2}
          imgWidth={599.15}
          imgHeight={423}
          className={styles.introduceItem2}
        />
        <IntroduceItem
          tagName={getFormatMessage('step_3')}
          itemTitle={getFormatMessage('dive_into_documentation')}
          itemTitleExtraInfo={getFormatMessage(
            'we_have_put_together_comprehensive_desc'
          )}
          itemButtonText={getFormatMessage('explore_documentation')}
          itemButtonClick={handleDocumentationClick}
          tagColor="purple"
          sideImgSrc={imgSrc3}
          imgWidth={780}
          imgHeight={600}
          even
          className={styles.introduceItem3}
        />

        <TryFreeBox
          defatultTextSize="small"
          extraDetailSize="small"
          className={styles.getStartedTryFreeBox}
          defatultText={getFormatMessage('try_for_free')}
          title={getFormatMessage('fueling_journey_to_success')}
          detailItems={[
            {
              text: getFormatMessage('we_believe_power_connected_desc'),
            },
            {
              text: getFormatMessage('has_opportunity_to_shine_desc'),
            },
            {
              text: getFormatMessage('we_are_handing_unlock_desc'),
            },
          ]}
        />
      </>
    </LayoutDefault>
  );
};
