import getFormatMessage from '../locales/get-format-message';
import { getLocaleLanguage, LanguageType } from './language';

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const interceptNumberOfChar = (str?: string, startIndex = 1) => {
  if (!str) return '';
  return str.slice(0, startIndex);
};

// 校验输入框输入字符长度
export const validateNameLength = (name: string) => {
  return (_rule: any, value: string) => {
    if (!value || !value.trim()) {
      return Promise.reject(
        getFormatMessage('required_message', {
          params: {
            name,
          },
        })
      );
    }

    if (value.trim().length > 50) {
      return Promise.reject(getFormatMessage('max_characters_50'));
    }

    return Promise.resolve();
  };
};

export const isJSONString = (str: string) => {
  try {
    JSON.parse(str);
    return true;
  } catch (error) {
    return false;
  }
};

export const getLangFormatText = (content = '') => {
  const isJSON = isJSONString(content);
  let formatContent = {
    [LanguageType.zh_CN]: content,
    [LanguageType.en_US]: content,
  };
  if (isJSON) {
    const contentObj = JSON.parse(content) || {};

    if (
      typeof contentObj === 'object' &&
      (LanguageType.zh_CN in contentObj || LanguageType.en_US in contentObj)
    ) {
      formatContent = contentObj;
    }
  }
  return formatContent;
};

export const getCurrentLangText = (content = '') => {
  const contentObj = getLangFormatText(content);
  const lang = getLocaleLanguage();
  if (!lang) {
    return contentObj[LanguageType.en_US] || '';
  }
  return contentObj[lang] || '';
};
