import React, { Component } from 'react';
import './style.scss';
import classnames from 'classnames';

interface IProps {
  readonly title: React.ReactNode;
  readonly className?: string;
  readonly icon?: React.ReactNode;
  readonly fontSize?: number;
  readonly hasArrow?: boolean;
  readonly onClick?: () => void;
}
class DropDownBox extends Component<IProps> {
  public constructor(props: IProps) {
    super(props);
  }
  render() {
    return (
      <div
        className={classnames('drop-down-box ', this.props.className)}
        onClick={this.props.onClick}
      >
        {this.props.icon ? this.props.icon : ''}
        <span
          className="box-title"
          style={
            this.props.fontSize
              ? { fontSize: `${this.props.fontSize}px` }
              : undefined
          }
        >
          {this.props.title}
        </span>
        {this.props.hasArrow ? (
          <span className="icon-arrow">
            <i className="mdi-set mdi-chevron-down"></i>
          </span>
        ) : (
          ''
        )}
      </div>
    );
  }
}
export { DropDownBox };
