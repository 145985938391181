import Dropdown from 'antd/lib/dropdown';
import { ReactComponent as IconEarth } from '../../../assets/svg/earth.svg';
import  IconLocation from '../../../assets/images/site-focus.png';
import { DropDownBox } from '../../../components/dropdown-box';
import { getConfig } from '../../../config';
import classNames from 'classnames';
import styles from './style.module.scss';
import { useEffect, useState } from 'react';
import {
  getLocaleLanguage,
  LanguageType,
  setLocaleLanguage,
} from '../../../lib/language';
import { ILanguageItem } from '../../../model';
import { ModalWrap } from '../../../components/modal-wrap';
import getFormatMessage from '../../../locales/get-format-message';

enum LanguageSite {
  Chinese = '中国站',
  International = 'International',
}
export const ItemSiteSwitchComponent = () => {
  // console.log('getConfig()',getConfig());
  const languageConfig = getConfig()?.languageConfig || '';
  const currentStoreLanguage = getLocaleLanguage() || LanguageType.en_US;
  // const [currentLanguage, setCurrentLanguage] = useState<number>(languageConfig?.currentLanguage || 0);
  const currentLanguage = languageConfig?.currentLanguage || 0;
  const languageList = languageConfig?.languageList || [];
  const [isShow, setIsShow] = useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageType | null>(
    null
  );
  const [selectedLink, setSelectedLink] = useState<string>('');
  const [selectedArea, setSelectedArea] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(false);
  
  const handleLanguageChange = (
    value: LanguageType,
    link: string,
    disabled: boolean
  ): Promise<boolean> => {
    return new Promise((resolve) => {
      if (disabled) {
        resolve(false);
      } else {
        if (link === '') {
          setLocaleLanguage(value);
          window.location.href = link; // 直接跳转到相同的链接
        } else {
          let completeURL = link;
          if (link.startsWith('//')) {
            completeURL = `https:${link}`;
          }
          try {
            const url = new URL(completeURL);
            const params = new URLSearchParams(url.search);
            params.set('ingame_language', value);
            url.search = params.toString();
            // console.log('url.toString()', url.toString());
            window.open(url.toString(), '_blank');
          } catch (error) {
            console.error(error);
            // 处理无效的 URL 错误
          }
        }
        setIsShow(false);
        resolve(true);
      }
    });
  };
  const getTitleAndContent = () => {
    let title, content;
    switch (selectedArea) {
      case LanguageSite.Chinese:
        title = getFormatMessage('site_switch_cn');
        content = getFormatMessage('site_switch_cn_desc');
        break;
      case LanguageSite.International:
        title = getFormatMessage('site_switch_en');
        content = getFormatMessage('site_switch_en_desc');
        break;
      default:
        title = getFormatMessage('site_switch_woa');
        content = getFormatMessage('site_switch_woa_desc');
        break;
    }
    return { title, content };
  };
  const showModal = () => {
    const { title, content } = getTitleAndContent();
    if (selectedLanguage) {
      return (
        <ModalWrap
         className={styles.switchChoiceModal}
          title={title}
          onOk={() =>
            handleLanguageChange(selectedLanguage, selectedLink, disabled)
          }
          cancelText={getFormatMessage('cancel')}
          oKText={getFormatMessage('confirm_to')}
          width="480px"
          maskClosable={true}
          onClose={() => {
            setIsShow(false);
          }}
        >
          {content}
        </ModalWrap>
      );
    }
    return null;
  };
  const renderLanguageItems = (items: ILanguageItem[], area: string) => {
    return items.map((item) => {
      const disable = currentStoreLanguage === item.key && item.link === '';
      const classNameDisable = `${item.key === LanguageType.en_US ? styles.en : styles.cn} ${disable ? styles.location : ''}`;
      return (
        <a
          className={classNameDisable}
          href={item.link}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            e.preventDefault();
            if (item.link === '') {
              handleLanguageChange(item.key, item.link, disable);
            } else {
              setSelectedLanguage(item.key);
              setSelectedLink(item.link);
              setSelectedArea(area);
              setDisabled(disable);
              setIsShow(true);
            }
          }}
        >
          {item.name}
          {disable ? (
            <img src={IconLocation} width={16} height={16} style={{position:'relative',marginRight: '12px' }}/>
          ) : null}
        </a>
      );
    });
  };

  const checkLanguageParam = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const languageParam = urlParams.get('ingame_language') as LanguageType;
    if (languageParam) { // 1.url有语言参数
      setLocaleLanguage(languageParam);
      urlParams.delete('ingame_language');
      const symbol = urlParams.toString() ? '?' : '';
      const newUrl = `${window.location.pathname}${symbol}${urlParams.toString()}${window.location.hash}`;
      window.history.replaceState({}, '', newUrl);
      handleLanguageChange(languageParam, '', false); // 更新
    } else {
      // 2.默认---英文站默认英文，中文站默认中文
      if (languageConfig !== undefined) {
        const storeLanguage = getLocaleLanguage();
        const newLanguage = storeLanguage || (currentLanguage === 0 ? LanguageType.en_US : LanguageType.zh_CN);
        // console.log('初始newLanguage==', storeLanguage, newLanguage, getLocaleLanguage(), newLanguage !== getLocaleLanguage());
        if (newLanguage !== getLocaleLanguage()) { 
          if(currentLanguage === 1){ // 中文站点才初始再刷新
            window.location.reload();
          }
          setLocaleLanguage(newLanguage);
        }
      }
    }
  };

  useEffect(() => {
    checkLanguageParam();
  }, []);

  const items = () => {
    return (
      <div className={styles.switchChoice} style={{ width: '160px' }}>
        {languageList.map((area) => (
          <div className={styles.typeItem}>
            <div className={styles.typeTitle}>{area.area}</div>
            {renderLanguageItems(area.items, area.area)}
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <li>
        <Dropdown
          overlay={items()}
          destroyPopupOnHide={false}
          overlayClassName="site-dropdown-bottom"
        >
          <a className={styles.actionAccount}>
            <DropDownBox
              className={styles.dropDownBox}
              hasArrow={false}
              fontSize={16}
              icon={
                <span className={styles.actionIcon}>
                  <IconEarth width={16} height={16} />
                </span>
              }
              title={''}
            />
          </a>
        </Dropdown>
      </li>
      {isShow && showModal()}
    </>
  );
};
