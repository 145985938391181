import { CommonAction, ICommonState } from './model';
import { CommonInitState } from './state';

export const commonReducer = (
  state = CommonInitState,
  action: CommonAction
): ICommonState => {
  switch (action.type) {
    case 'userInfo':
      return { ...state, userInfo: action.result };
    default:
      return state;
  }
};
