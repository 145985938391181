import { useState, useEffect } from 'react';
import styles from './style.module.scss';
import { RightOutlined } from '@ant-design/icons';
import { Tag, Button } from 'antd';
import { BoxFrame } from '../../common/box-frame';
import classNames from 'classnames';
interface ITabItem {
  readonly listInfo?: any;
  readonly listIcon?: any;
  readonly listTitle?: string;
  readonly defaultChecked?: boolean;
}

interface IProps {
  readonly items?: ITabItem[];
  readonly sideImgSrc?: string;
  readonly tagName?: string;
  readonly itemTitle?: string;
  readonly itemTitleExtraInfo?: string;
  readonly itemButtonText?: string;
  readonly itemButtonHref?: string;
  readonly itemButtonClick?: () => void;
  readonly radioName?: string;
  readonly tagColor?: string;
  readonly even?: boolean;
  readonly hasBg?: boolean;
  readonly imgWidth?: number | string;
  readonly imgHeight?: number | string;
  readonly className?: any;
  readonly id?: any;
}

export const IntroduceItem = (props: IProps) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [timerEnabled, setTimerEnabled] = useState<boolean>(true);

  useEffect(() => {
    if (props.items && timerEnabled) {
      const interval = setInterval(() => {
        setSelectedIndex((prevIndex) => (prevIndex + 1) % props.items!.length);
      }, 4800);

      return () => clearInterval(interval);
    }
  }, [props.items, timerEnabled]);

  const handleInputChange = (index: number) => {
    setSelectedIndex(index);
  };

  const handleMouseEnter = () => {
    setTimerEnabled(false);
  };

  const handleMouseLeave = () => {
    setTimerEnabled(true);
  };
  return (
    <BoxFrame
      className={classNames(
        styles.introduceItemWrapper,
        props.even && styles.isEven,
        props.hasBg && styles.hasBg,
        props.className
      )}
      innerClassName={styles.introduceItem}
      id={props.id}
    >
      <div className={classNames('item-left', props.items && 'has-items-list')}>
        <div className={styles.introduceItemInfoBox}>
          <Tag
            className={
              (props.tagColor === 'orange' && 'g-ant-orange') ||
              (props.tagColor === 'green' && 'g-ant-green') ||
              (props.tagColor === 'blue' && 'g-ant-blue') ||
              (props.tagColor === 'yellow' && 'g-ant-yellow') ||
              (props.tagColor === 'purple' && 'g-ant-purple') ||
              ''
            }
          >
            {props.tagName}
          </Tag>
          <p className="item-title">{props.itemTitle}</p>
          <p className="item-extra">{props.itemTitleExtraInfo}</p>
          {props.itemButtonText && (
            <Button
              type="primary"
              className="c-btn-size-md c-btn-has-arrow"
              onClick={props.itemButtonClick}
              href={props.itemButtonHref}
            >
              {props.itemButtonText}
              <RightOutlined />
            </Button>
          )}
        </div>
        <ul className={styles.introduceItemSelect}>
          {props.items?.map((item, index) => (
            <li
              className={classNames(
                styles.selectItemList,
                props.even && styles.isEven
              )}
            >
              <input
                type="radio"
                name={props.radioName}
                defaultChecked={item.defaultChecked}
                className="c-radio-input"
                checked={index === selectedIndex}
                onChange={() => handleInputChange(index)}
                onMouseEnter={handleMouseEnter} // 停止计时器
                onMouseLeave={handleMouseLeave} // 启用计时器
              />
              <div className="c-select-item-inner">
                <h5 className="c-item-title">
                  <span className="title-icon">{item.listIcon}</span>
                  <p className="title-text">{item.listTitle}</p>
                </h5>
                <p
                  className="c-item-detail-info"
                  dangerouslySetInnerHTML={{ __html: item.listInfo }}
                ></p>
              </div>
              <span
                className={classNames(
                  'c-progress-bar',
                  index === selectedIndex && 'g-animation-progress-bar'
                )}
              ></span>
            </li>
          ))}
        </ul>
      </div>
      <div className="item-right">
        <img
          src={props.sideImgSrc}
          width={props.imgWidth}
          height={props.imgHeight}
        />
      </div>
    </BoxFrame>
  );
};
