import { log } from './log';
import { Uuid } from './uuid';

function getActionId(): string {
  const uuid = new Uuid();
  return uuid.uuid;
}

function onCompleted(
  startTime: number,
  messagePrefix: string,
  logEndTime: (info: string, rawTime: number) => void
): () => void {
  return (): number => {
    const now = getNow();
    if (!now) {
      return 0;
    }

    const rawTime = now - startTime;
    logEndTime(messagePrefix, rawTime);
    return rawTime | 0;
  };
}

function getNow(): number | null {
  const now = performance?.now ? performance.now() : null;
  if (now === null) {
    log().warn(
      '[Timing] invoked but performance.now not found - are you using this outside the renderer?'
    );
  }
  return now;
}

// ms
function logEndTime(message: string, rawTime: number) {
  const timeInSeconds = (rawTime / 1000).toFixed(3);
  log().info(`${message}${timeInSeconds}s`);
}

export function startTimer(action: string) {
  const startTime = getNow();
  if (startTime === null) {
    return {
      done: () => {},
    };
  }

  const actionId = getActionId();
  const messagePrefix = `${action}|${actionId}|`;
  return {
    actionId,
    done: onCompleted(startTime, messagePrefix, logEndTime),
  };
}
