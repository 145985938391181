import React, { ReactNode } from 'react';
import styles from './style.module.scss';
interface IProps {
  readonly title?: string;
  readonly detail?: string;
  readonly icon?: ReactNode;
}
export const IconDetailItem = (props: IProps) => {
  return (
    <div className={styles.iconDetailItem}>
      {props.icon && <span className="icon-space">{props.icon}</span>}
      <p className="item-text is-title">{props.title}</p>
      <p className="item-text">{props.detail}</p>
    </div>
  );
};
