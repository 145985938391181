import styles from './style.module.scss';
import { BoxFrame } from '../../common/box-frame';
import { ItemTitle } from '../../common/item-title';
import StatementIcon1 from '../../../assets/images/c-statement-box-img-01.png';
import StatementIcon2 from '../../../assets/images/c-statement-box-img-02.png';
import getFormatMessage from '../../../locales/get-format-message';

export const StatementBox = () => {
  return (
    <div className={styles.statementBox}>
      <ItemTitle
        type="inline"
        itemsExtraSize="large"
        itemsExtraMaxWidth={938}
        items={[
          { text: getFormatMessage('security'), textColor: 'green' },
          { text: getFormatMessage('statement') },
        ]}
        itemsExtra={[
          {
            text: getFormatMessage('in_game_follows_highest_standard1'),
          },
          {
            text: getFormatMessage('in_game_follows_highest_standard2'),
          }
        ]}
      />
      <BoxFrame innerClassName={styles.statementInnerBox}>
        <span className="statemen-icon-box">
          <img src={StatementIcon1} width={200} height={200} />
        </span>
        <span className="statemen-icon-box">
          <img src={StatementIcon2} width={200} height={200} />
        </span>
      </BoxFrame>
    </div>
  );
};
