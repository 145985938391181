import BackTopBoxIcon from '../../../assets/images/c-back-top-icon.png';
import styles from './style.module.scss';
import classNames from 'classnames';
interface IProps {
  readonly scrollPosition?: 'bottom' | 'top' | '';
  readonly scrolling?: boolean;
  readonly onclick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export const BackTopBox = (props: IProps) => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    props.onclick?.(event);
  };
  return (
    <div
      className={classNames(
        styles.backTopBox,
        props.scrolling ? styles.isScrolling : styles.isStopScrolling,
        props.scrollPosition === 'bottom'
          ? styles.isDistanceBotton
          : styles.isNotDistanceBotton,
        props.scrollPosition === 'top' && styles.hasBackTop
      )}
      onClick={handleClick}
    >
      <img src={BackTopBoxIcon} width={64} height={108} />
    </div>
  );
};
