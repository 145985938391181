import { messageError } from '../components/message';
import { withAPIAccount } from '../lib/http/api';
import { APIError, parsedResponse } from '../lib/http/http';
import { AccountType } from '../model';
import { INews, IQueryNews } from '../model/news';
import { API_PREFIX } from './config';

// 用户信息
export const getUpgradeNews = (query: IQueryNews) =>
  withAPIAccount(AccountType.auto, async (api) => {
    try {
      const url = `${API_PREFIX}/news/public`;
      const response = await api.commonRequest('GET', url, query);

      const res = await parsedResponse<{ list: ReadonlyArray<INews> } | null>(
        response,
        'data'
      );
      return res;
    } catch (error) {
      console.log('failed: upgrade news', error);
      if (error instanceof APIError && error.responseStatus !== 401) {
        messageError(error.message);
      }
      return null;
    }
  });
