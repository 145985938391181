import { debounce } from 'lodash';
import getFormatMessage from '../../locales/get-format-message';
import { AccountType } from '../../model';
import { getGCloudAPIEndpoint } from './endpoint';
import { HTTPMethod, parsedResponse, request } from './http';

const requestCache: { [key: string]: Function } = {};

const getEndpoint = (type: AccountType) => {
  switch (type) {
    case AccountType.auto:
      return getGCloudAPIEndpoint();
    default:
      return getGCloudAPIEndpoint();
  }
};

export async function withAPIAccount<T>(
  type: AccountType,
  fn: (api: API) => Promise<T>
) {
  const endpoint = getEndpoint(type);
  if (endpoint === null) {
    throw new Error(getFormatMessage('please_sign_in_first'));
  }
  const api = new API(endpoint);
  return fn(api);
}

export class API {
  private privateEndpoint: string;
  public constructor(endpoint: string) {
    this.privateEndpoint = endpoint;
  }

  public async commonRequest(
    method: HTTPMethod,
    path: string,
    body?: Object,
    customHeaders?: Object,
    timeout?: number
  ): Promise<Response> {
    return await request(
      this.privateEndpoint,
      method,
      path,
      body,
      {
        ...(customHeaders || {}),
      },
      timeout || 60000
    );
  }

  public get endpoint() {
    return this.privateEndpoint;
  }
}

async function withAPIAccountRequest<T>(
  method: HTTPMethod,
  path: string,
  body?: Object,
  customHeaders?: Object,
  timeout?: number
): Promise<T> {
  return withAPIAccount(AccountType.auto, async (api) => {
    const response = await api.commonRequest(
      method,
      path,
      body,
      customHeaders,
      timeout
    );
    return await parsedResponse<T>(response, 'data');
  });
}

async function debounceRequest<T>(
  method: HTTPMethod,
  path: string,
  params?: Object,
  customHeaders?: Object,
  timeout?: number
): Promise<T> {
  const requestKey = `${path}|${params ? JSON.stringify(params) : ''}`;

  if (!requestCache[requestKey]) {
    requestCache[requestKey] = debounce(withAPIAccountRequest, 500, {
      leading: true,
      trailing: false,
    });
  }

  return await requestCache[requestKey](
    'GET',
    path,
    params,
    customHeaders,
    timeout
  );
}

export async function get<T>(
  path: string,
  body?: Object,
  customHeaders?: Object,
  timeout?: number
): Promise<T> {
  return debounceRequest('GET', path, body, customHeaders, timeout);
}

export async function post<T>(
  path: string,
  body?: Object,
  customHeaders?: Object,
  timeout?: number
): Promise<T> {
  return debounceRequest('POST', path, body, customHeaders, timeout);
}
