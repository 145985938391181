export default {
    // 中文配置
    let_build_an: '在这里，',
    excellent_game_from_here: '创建属于你的游戏新世界',
    in_game_services_focus: 'InGame 服务让你专注于创新',
    get_started_for_free: '立即开始',
    // 全新
    the_ingame:'',
    platform_is_now_live:'InGame平台全新上线',
    in_game_services_focus_new:'专为',
    in_game_services_focus_new2:'全球发行游戏',
    in_game_services_focus_new3:'设计的游戏服务平台，研发无忧，创意无限！',
    learn_more_btn:'立即开始',
    // 特别企划
    in_game_special:'',
    special_campaign:'InGame特别企划',
    in_game_services_focus_special:'携手春笋计划，助力潜力项目成长',

    in_game:'InGame',
    is_a_adaptable:'全方位游戏后端服务, ',
    gaming_backend: '开箱即用',
    crafting_dynamic_desc: '打造全平台多人在线联机游戏',
    harness_power_our_core_desc: '聚焦游戏后端核心能力，打造最顶尖的多人在线游戏生态',
    explore_multiplayer: '探索多人游戏',
    player_centric: '贴近玩家',
    supports_login_matchmaking_desc1: '支持全平台登录、匹配',
    supports_login_matchmaking_desc2:'低延迟，就近匹配，快速开启单局',
    supports_login_matchmaking_desc3:' 轻松丰富游戏内在线玩家游戏体验',
    flexible_matchmaking: '灵活匹配',
    supports_custom_matchmaking_desc1: '支持自定义任意匹配逻辑',
    supports_custom_matchmaking_desc2: '根据玩家画像可设置不同匹配池以支持对应的优先匹配规则设置',
   
    highly_scalable: '高度可扩展',
    expand_game_backend_desc1: '使用 IRPC 通过 Golang 或JS 自定义默认服务行为',
    expand_game_backend_desc2: '打造专属游戏逻辑，轻松扩展平台功能',
    interactive_social: '互动社交',
    enabling_players_from_desc: '轻松实现多场景跨平台互动',
    create_game_social_layer_desc: '打造游戏自己的社交圈层，让来自不同平台的玩家可以在游戏中进行互动交流',
    cross_platform: '跨平台',
    supports_cross_platform_desc1: '支持跨平台好友功能',
    supports_cross_platform_desc2: '不限平台、不限设备',
    supports_cross_platform_desc3: '轻松建立深度社交关系，提升游戏粘性',
    real_time_interaction: '实时互动',
    high_quality_text_and_voice_desc1: '提供高质量文本、语音实时互动聊天',
    high_quality_text_and_voice_desc2: '支持各种游戏模式下的应用场景',
    high_quality_text_and_voice_desc3: '提升游戏的沟通效率和协作体验',

    competitive_ranking: '竞技排名',
    scalable_distributed_desc1: '可扩展的分布式游戏排名，轻松实现千万级别游戏玩家同榜',
    scalable_distributed_desc2: '支持按需动态创建十万级别榜单，快速实现不同地区的榜单排名',
    content_update: '内容更新',
    all_platform_universal_desc: '支持游戏内容全球动态下发',
    develop_a_universal_desc: '打造全平台通用的内容分发技术，满足游戏长线运营中的内容更新诉求',
    explore_content: '探索内容',
    leading_infrastructure: '领先的基础设施',
    server_nods_cover_desc1: '服务器节点覆盖全球',
    server_nods_cover_desc2: '一次发布，全球分发',
    game_appropriate: '契合游戏应用场景',
    provides_unified_game_version_desc1: '云端简易存储，分钟内完成应用更改及部署',
    provides_unified_game_version_desc2: '为开发者提供全面、精准、动态的配置下发能力',
    rapid_dynamic_deployment: '快速触达，动态下发',
    cloud_based_simple_storage_desc1: '云端简易存储，分钟内完成应用更改及部署',
    cloud_based_simple_storage_desc2: '为开发者提供全面、精准、动态的配置下发能力',
    economic_system: '经济系统',
    build_long_term_operational_desc: '丰富多样化游戏经济生态',
    create_game_social_different_desc: '构建长线运营商业生态，结合移动端丰富的研运经验和成熟的网游运营模式为业务赋能',
    explore_economy: '探索经济',
    comprehensive_economic_ecosystem: '完备的经济生态',
    a_complete_economic_system_desc1: '提供一整套完善的经济系统',
    a_complete_economic_system_desc2: '包括支付、商城、游戏内资产和背包仓库丰富玩家的游戏体验',
    flexible_asset_management: '灵活的资产管理',
    in_game_item_and_virtual_desc1: '提供游戏内道具和虚拟货币管理',
    in_game_item_and_virtual_desc2:'同时配套仓库、背包服务等存储空间解决方案',
    in_game_item_and_virtual_desc3:'为游戏提供更加安全、高效、便捷的游戏内资产管理',
    data_analysis: '数据分析',
    creating_mature_data: '搭建智能化、全方位数据分析系统',
    build_data_analysis_desc: '构建数据分析互动生态，帮助用户监测性能、了解玩家并提供更有吸引力的体验',
    explore_analytics: '探索分析',
    mature_analytics_system: '成熟的经分体系',
    easily_understand_your_name_desc1: '轻松洞察自身游戏数据表现',
    easily_understand_your_name_desc2: '全面掌握游戏经营数据，智能分析优化游戏表现，助力业务决策',
    efficient_self_service_desc: '高效的自助分析',
    convenient_and_efficient_data_desc1: '提供便捷高效的数据上报',
    convenient_and_efficient_data_desc2:'方便开发者进行自定义场景下的自助探索分析',
    intelligent_performance_monitoring: '智能化性能监控',
    fine_grained_event_desc1: '提供细粒度事件、进程监控，支持实时告警，故障自愈',
    fine_grained_event_desc2: '帮助开发者快速定位并解决异常问题，为全球在线游戏稳定安全保驾护航',
    ready_to_build_game: '准备好构建你的游戏了吗？',
    get_started_now: '现在支持免费启动!',
    let_innovate_experiences_desc: '即刻起一起共建InGame游戏宇宙',
    ingame_upgrade_notification: 'InGame 升级通知',
}