import { withAPIAccount } from '../lib/http/api';
import { APIError, parsedResponse } from '../lib/http/http';
import { AccountType, IAPIAppConfig } from '../model';
import { APP_CONFIG_PREFIX } from './config';

import { messageError } from '../components/message';

export const getAppConfigApi = (hostname: string) =>
  withAPIAccount(AccountType.auto, async (api) => {
    try {
      const url = `${APP_CONFIG_PREFIX}/${hostname}`;
      const response = await api.commonRequest('GET', url);
      return await parsedResponse<IAPIAppConfig>(response, 'data');
    } catch (error) {
      if (error instanceof APIError) {
        messageError(error.apiError?.message);
      }
      return null;
    }
  });
